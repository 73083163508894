import { useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button } from '@mantine/core';

import { ContentLayout, BackButton } from 'components';
import { OrderDto } from 'shared';
import { useEditOrders } from 'hooks';
import { Form } from '../form';
import { Mode } from '../../constants';

type PropType = {
  onExit: () => void;
};

export const Edit = ({ onExit }: PropType): JSX.Element => {
  const [mode, setMode] = useState<Mode>(Mode.EDIT);
  const navigate = useNavigate();
  const { id = '' } = useParams();
  const {
    order,
    initialValues,
    cards,
    signature,
    payment,
    linkOrders,
    isPaying,
    isLoading,
    isSkipping,
    isUpdating,
    isResending,
    isCanceling,
    isCancelWithSkip,
    isRefunding,
    generationPDFPaymentId,
    clubMemberShip,
    skip,
    handleRefund,
    handleSubmit,
    handleCancel,
    handleRecalculatePaidOrder,
    handleRegenerateReceipt,
    handleSendReceipt,
    resend,
    confirmAndPay,
    setRecalculateOrder,
  } = useEditOrders({ id, queryKey: 'cycle', onExit });

  const { number } = order ?? {};
  const title = `Order ${number}`;

  return (
    <ContentLayout
      title={title}
      processing={isUpdating || isCanceling}
      isLoading={isLoading}
      leftAction={<BackButton component={Link} onClick={() => navigate(-1)} />}
      rightAction={
        <Button
          disabled={mode === Mode.HISTORY}
          onClick={() => setMode(Mode.HISTORY)}
        >
          See Compliance History
        </Button>
      }
    >
      {initialValues && (
        <Form
          isCycleOrder
          mode={mode}
          clubMemberShip={clubMemberShip}
          goBack={() => setMode(Mode.EDIT)}
          goChangeHistory={() => setMode(Mode.CHANGE_HISTORY)}
          initialValues={initialValues}
          linkOrders={linkOrders}
          cards={cards}
          signature={signature}
          payment={payment}
          order={order as OrderDto}
          loading={isUpdating}
          isPaying={isPaying}
          isRefunding={isRefunding}
          isSkipping={isSkipping}
          isResending={isResending}
          isCancelWithSkip={isCancelWithSkip}
          generationPDFPaymentId={generationPDFPaymentId}
          skip={skip}
          resend={resend}
          handleRefund={handleRefund}
          cancel={handleCancel}
          confirmAndPay={confirmAndPay}
          setRecalculateOrder={setRecalculateOrder}
          handleSubmit={handleSubmit}
          handleSendReceipt={handleSendReceipt}
          handleRecalculatePaidOrder={handleRecalculatePaidOrder}
          handleRegenerateReceipt={handleRegenerateReceipt}
        />
      )}
    </ContentLayout>
  );
};
