import { CustomerListDto, WineTypeTitle } from 'shared/types';

export const normalizeCustomerList = (data: CustomerListDto[]) => {
  return data.map((i) => ({
    ...i,
    statusCustomer: {
      has_error: i.has_errors,
      has_all_fields_populated: i.has_all_fields_populated,
      has_club_membership_request: i.has_club_membership_request,
    },
    clubAddOn: i.wine_club_add_on?.wine_type
      ? WineTypeTitle[i.wine_club_add_on?.wine_type]
      : '-',
  }));
};
