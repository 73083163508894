import { CustomerService, InventoryService, LocationService } from 'App/api';
import { useApiQuery } from 'hooks';
import { useMemo } from 'react';
import {
  getSelectOptions,
  LocationTypes,
  LOCATION_KEY,
  SupplyType,
} from 'shared';

export const useDeliveryData = ({
  customerId,
  primaryLocationId: location_id,
  supplyType,
}: {
  customerId?: string;
  primaryLocationId?: string;
  supplyType?: SupplyType;
}) => {
  const locationType =
    supplyType === SupplyType.FULFILLMENT
      ? LocationTypes.FULFILMENT
      : LocationTypes.INTERNAL;

  const { data: locations } = useApiQuery([LOCATION_KEY, locationType], () =>
    LocationService.getLocations({
      types: [locationType],
    }),
  );

  const { data: addresses } = useApiQuery(
    ['addresses', customerId],
    () => CustomerService.getAddresses({ id: customerId }),
    {
      enabled: !!customerId,
    },
  );

  const defaultAddress = useMemo(
    () => addresses?.items.find(({ is_default }) => is_default),
    [addresses?.items],
  );

  const { data } = useApiQuery(
    ['inventory-location', location_id],
    () => InventoryService.getLocations({ location_id }),
    {
      enabled: !!location_id,
    },
  );

  const primaryLocationOptions = useMemo(() => {
    if (!locations) return [];

    return getSelectOptions(locations.items);
  }, [locations]);

  const inventoryLocationOptions = useMemo(() => {
    if (!data) return [];

    return getSelectOptions(data.items);
  }, [data]);

  const addressOptions = useMemo(() => {
    if (!addresses) return [];

    return getSelectOptions(
      addresses.items.map(({ id = '', street_address_one }) => ({
        id,
        name: street_address_one ?? '',
      })),
    );
  }, [addresses]);

  return {
    inventoryLocationOptions,
    primaryLocationOptions,
    addressOptions,
    defaultAddress,
  };
};
