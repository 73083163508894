import { useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { ShipmentSalesOrdersService } from 'App/api';
import { useApiQuery } from 'hooks';
import {
  CorporateOrderDto,
  getCheckoutSummary,
  getClubMembership,
} from 'shared';
import { getGeneralInformation } from '../helper';

export const useEditSuborder = () => {
  const { subOrderId = '' } = useParams();
  const { state: choice }: { state?: string } = useLocation();

  const { data: order, isLoading } = useApiQuery(
    ['getSuborder', subOrderId],
    () => ShipmentSalesOrdersService.getOne({ id: subOrderId }),
  );

  const { customer, checkout } = order ?? {};

  const initialValues = useMemo(
    () => order && getGeneralInformation(order as unknown as CorporateOrderDto),
    [order],
  );

  const defaultClubMembership = useMemo(
    () =>
      getClubMembership({
        mainTier: customer?.main_tier,
        addOnTier: customer?.add_on_tier,
      }),
    [customer],
  );

  const summary = useMemo(
    () => checkout && getCheckoutSummary(checkout),
    [checkout],
  )?.filter((i) => i[0] !== 'Gratuity' && i[0] !== 'Member’s Discount');

  return {
    isLoading,
    initialValues,
    order,
    summary,
    defaultClubMembership,
    giftPackageName: choice,
  };
};
