import { Badge } from '@mantine/core';
import { OrderPaymentStatus, orderPaymentStatusLabels } from 'shared';

export const PaymentStatusBadge = ({
  payment_status,
}: {
  payment_status: OrderPaymentStatus;
}) => {
  const color = [OrderPaymentStatus.UNPAID, OrderPaymentStatus.ERROR].includes(
    payment_status,
  )
    ? 'red'
    : 'blue';

  return (
    <Badge variant="filled" color={color}>
      {orderPaymentStatusLabels[payment_status]}
    </Badge>
  );
};
