import { Button, Modal, Text } from '@mantine/core';

type Props = {
  errors: string[];
  setErrors: React.Dispatch<React.SetStateAction<string[]>>;
  setFile: React.Dispatch<React.SetStateAction<File | null>>;
};

export const UploadErrorModal = ({ errors, setErrors, setFile }: Props) => {
  const hasErrors = !!errors.length;
  const title = 'Upload Error';

  return (
    <Modal
      size="xl"
      opened={hasErrors}
      onClose={() => {
        setFile(null);
        setErrors([]);
      }}
      title={
        <Text size="sm" weight={600}>
          {title}
        </Text>
      }
    >
      <Text mb={15}>
        The file you tried to upload is not correct. Check the file and try
        again.
      </Text>
      {errors.map((error) => (
        <Text key={error} color="red">
          {error}!
        </Text>
      ))}
      <Button
        mt={15}
        fullWidth
        onClick={() => {
          setFile(null);
          setErrors([]);
        }}
      >
        Okay
      </Button>
    </Modal>
  );
};
