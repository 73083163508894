import { ClubTierDto, ClubTierLevel } from '../customer/clubTier';
import { WineType, PaginatedResponse } from '../common';
import { DeliveryMethod } from '../baseOrder';

export interface WineSelection {
  sku_id?: string;
  wine_type: string;
  bottle_quantity: number;
  quantity: number;
  sku_number?: string;
  sku_product_name?: string;
  is_swappable: boolean;
  key?: string;
}

export interface Suggestion {
  sku_id?: string;
  club_tier_id: string;
  max_quantity: number;
  key?: string;
  sku_number?: string;
  sku_product_name?: string;
}

export type WineSelectionDto = {
  wine_selections: WineSelection[];
};

export type SuggestionsDto = {
  suggestions: Suggestion[];
};

export type ShippingDetails = {
  freight_company?: string | null;
  freight_method?: string | null;
};

export interface ShipmentCycleDto extends ShippingDetails {
  name: string;
  club_tier_id: string;
  club_tier?: ClubTierDto;
  cycle_start_date?: Date | null;
  cycle_start_time?: Date | null;
  member_list_close_date?: Date | null;
  end_of_customization_window?: Date | null;
  billing_date?: Date | null;
  billing_time?: Date | null;
  re_billing_date?: Date | null;
  re_billing_time?: Date | null;
  estimated_shipping_date?: Date | null;
  pick_up_deadline?: Date | null;
  wine_selections: WineSelection[];
  suggestions: Suggestion[];
  status?: ShipmentCycleStatus;
}

export interface ShipmentCyclePayload {
  name: string;
  club_tier_id: string;
  cycle_start_datetime?: Date | null;
  member_list_close_date?: string | null;
  end_of_customization_window?: string | null;
  billing_datetime?: Date | null;
  re_billing_datetime?: Date | null;
  estimated_shipping_date?: string | null;
  pick_up_deadline?: string | null;
  wine_selections: WineSelection[];
  suggestions: Suggestion[];
}

export interface ShipmentCycleEdit
  extends Omit<
    ShipmentCyclePayload,
    | 'club_tier_id'
    | 'cycle_start_datetime'
    | 'billing_datetime'
    | 're_billing_datetime'
    | 'estimated_shipping_date'
  > {
  club_tier: ClubTierDto;
  cycle_start_datetime?: string | null;
  billing_datetime?: string | null;
  re_billing_datetime?: string | null;
  estimated_shipping_date: string | null;
  status: ShipmentCycleStatus;
}

export enum ShipmentCycleStatus {
  DRAFT = 'draft',
  READY = 'ready',
  PUBLISHED = 'published',
  COMPLETED = 'completed',
  PUBLISH_IN_PROGRESS = 'publishing_in_progress',
}

export interface ShipmentCycleSummaryItem {
  name: string;
  status: ShipmentCycleStatus;
  billing_cycle_start?: string;
  active_members: number;
  skipped: number | null;
  processed: number | null;
  unpaid: number | null;
  pending: number | null;
  closed: number | null;
}

export interface ShipmentCycleShortItem {
  id: string;
  name: string;
  status: ShipmentCycleStatus;
  club_tier_id: string;
}

export interface ShipmentCycleSummaryItemOutput
  extends Omit<ShipmentCycleSummaryItem, 'status'> {
  status: string;
}

export interface ShipmentCycleCollectionItemDto {
  id: string;
  summary: ShipmentCycleSummaryItem;
  details: ShipmentCycleSummaryItem[];
}

export interface ShipmentCycleCollectionItemOutput
  extends ShipmentCycleSummaryItemOutput {
  id: string;
  details: ShipmentCycleSummaryItemOutput[];
}

export interface ShipmentCycleDetailItemDto {
  id: string;
  first_name: string;
  last_name: string;
  tier: {
    id: string;
    level: ClubTierLevel;
    name: string;
  };
  add_on: {
    id: string;
    level: ClubTierLevel;
    name: string;
  } | null;
  choice: {
    wine_type: WineType;
    bottle_quantity: 0;
  };
  has_errors: boolean;
  delivery_method: DeliveryMethod;
  unpaid: string | null;
  pending: string | null;
}

export interface ShipmentCycleDetailsDto
  extends PaginatedResponse<ShipmentCycleDetailItemDto> {
  shipment_cycle: ShipmentCycleShortItem;
}

export interface ShipmentCycleDetailItemOutput {
  id: string;
  member_name: string;
  tier: string;
  choice: string;
  delivery_method: DeliveryMethod;
  unpaid: string;
  pending: string;
  has_errors: boolean;
}

export interface ShipmentCycleInventoryDetailsLocation {
  location_id: string;
  location_name: string;
  total_quantity: number;
  items: {
    sku_id: string;
    sku_number: string;
    sku_product_name: string;
    left_quantity: number;
    committed_quantity: number;
    inventory_quantity: number;
  }[];
}

export interface ShipmentCycleInventoryDetails {
  id: string;
  bottle_quantity: number;
  total_quantity: number;
  fulfillment_shipment_quantity: number;
  in_house_shipment_quantity: number;
  pick_up_quantity: number;
  details: ShipmentCycleSummaryItem[];
  summary: ShipmentCycleSummaryItem;
  locations: ShipmentCycleInventoryDetailsLocation[];
}

export enum ClubMembershipChangeReason {
  TOO_MUCH_WINE = 'Too Much Wine',
  FINANCIAL = 'Financial',
  TRAVELING = 'Traveling',
  NOT_INTERESTED = 'Not interested in current wine offerings',
  ORDERED_RECENTLY_ONLINE_OR_IN_TASTING_ROOM = 'Ordered recently online or in Tasting Room',
  OTHER = 'Other',
}
