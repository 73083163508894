import * as Yup from 'yup';
import {
  requiredNumberValidationRule,
  requiredStringValidationRule,
} from 'shared';

const giftPackageItemSchema = Yup.object({
  sku_name: requiredStringValidationRule('Product'),
  quantity: requiredNumberValidationRule('Qnt'),
});

const giftPackageItemsSchema = Yup.object({
  name: Yup.string(),
  gift_package_items: Yup.array().of(giftPackageItemSchema),
});

const giftPackagesSchema = Yup.array().of(giftPackageItemsSchema);

export const corporateOrderSchema = Yup.object({
  customer_id: requiredStringValidationRule('Customer'),
  customer_card_id: requiredStringValidationRule('Card'),
  freight_company: requiredStringValidationRule('Freight Company'),
  freight_method: requiredStringValidationRule('Freight Method'),
  gift_packages: giftPackagesSchema,
});
