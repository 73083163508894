import {
  OrderPaymentStatus,
  OrderSource,
  OrderStatus,
  SupplyType,
} from './baseOrder';
import { Modifier } from './culinary';
import { OrderType, PaymentStatus, ServiceClass } from './membersClub';
import {
  Payer,
  PaymentMethod,
  PosSalesItem,
  PosSalesPaymentType,
} from './orders';

export enum TransactionType {
  REFUND = 'refund',
  CHARGE = 'charge',
}

export type ItemTransaction = {
  id: string;
  type: 'general' | 'shipment_cycle' | 'pos';
  created_at: string;
  product_name: string;
  quantity: number;
  sku_price: number;
  club_membership_discount_per_unit_fraction: number;
  promo_code_discount_per_unit_fraction: number;
  sub_total: number;
  sku_discounted_price: number;
  total_tax: number;
  total_fee: number;
  quantity_of_split_parts_per_unit: number;
  total_promo_code_discount: number;
  total: number;
  sales_order_item: {
    id: string;
    payment_status: OrderPaymentStatus;
    refunded_quantity: number;
  };
  modifiers?: Modifier[];
};

export type Detalization = {
  id: string;
  created_at: string;
  total: number;
  tips: number;
  total_items_tax: number;
  total_items_fee: number;
  total_items_club_membership_discount: number;
  total_items_promo_code_discount: number;
  total_orders_shipping_cost: number;
  total_orders_shipping_cost_promo_code_discount: number;
  total_orders_shipping_tax: number;
  total_items_subtotal: number;
  small_charge_fee: number;
  promo_code: {
    id: string;
    name: string;
  } | null;
  items: ItemTransaction[];
};

export type PaymentsDto = {
  items: Payments[];
};

export interface Payments {
  id: string;
  key: string;
  created_at: string;
  amount: number;
  tips: number;
  change: number;
  refund: number;
  to_refund: number;
  discounts: number;
  items_price: number;
  taxes: number;
  fees: number;
  total: number;
  succeeded_at: string;
  refunded_at: string;
  items: PosSalesItem[];
  method: PaymentMethod;
  type: PosSalesPaymentType;
  status: PaymentStatus;
  sales_orders?: {
    id: string;
    number: number;
    payment_status: OrderPaymentStatus;
    processed_in: OrderSource | null;
    service_class: ServiceClass;
    source: OrderSource;
    status: OrderStatus;
    supply_type: SupplyType;
    total: number;
    type: OrderType;
  }[];
  transactions: {
    id: string;
    is_correction: boolean;
    created_at: string;
    updated_at: string;
    succeeded_at: string;
    type: TransactionType;
    status: PaymentStatus;
    amount: number;
    detalization: Detalization;
  }[];
  payer: Payer | null;
  payer_name: string;
  card_brand: string | null;
  card_last4: string | null;
  signature_url: string;
  three_d_secure_url: string;
  s3_receipt_path: string | null;
  is_receipt_generated: boolean;
}
