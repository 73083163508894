import { ReactNode } from 'react';
import { Grid, Text } from '@mantine/core';
import {
  getCurrencyString,
  getFullName,
  Payer,
  PaymentMethod,
  PaymentStatus,
} from 'shared';
import { PaymentStatusBadge } from './PaymentStatusBadge';

type Props = {
  status: PaymentStatus;
  succeededAt: string;
  createAt?: string;
  amount: number;
  smallChargeFee?: number;
  payer: Payer | null;
  payerName?: string;
  method: PaymentMethod;
  paymentMethod?: string;
  change: number;
  isRefunded?: boolean;
  children: ReactNode;
};

export const PaymentInformation = ({
  amount,
  smallChargeFee,
  createAt,
  succeededAt,
  payer,
  payerName,
  method,
  paymentMethod,
  change,
  status,
  isRefunded = false,
  children,
}: Props) => {
  const title = isRefunded ? 'Refunded' : 'Payment Information';
  const amountTransaction = getCurrencyString(amount);
  const smallChargeFeeTransaction =
    smallChargeFee && getCurrencyString(smallChargeFee);

  const payerFullName = payer ? getFullName(payer) : payerName;

  const hasChange = method === PaymentMethod.cash && !isRefunded && !!change;

  return (
    <Grid mb="xl" gutter="xl">
      <Grid.Col mb="xs">
        <Text size="lg" weight={600}>
          {title}
        </Text>
      </Grid.Col>
      <Grid.Col>
        <Grid gutter="md" align="center">
          <Grid.Col>
            <Text size="md" weight={600}>
              {payerFullName}
            </Text>
          </Grid.Col>
          <Grid.Col span={6}>
            <Grid>
              <Grid.Col span={2}>
                <Text size="sm">{succeededAt || createAt}</Text>
              </Grid.Col>
              <Grid.Col span={4}>
                <Text size="sm">{paymentMethod}</Text>
              </Grid.Col>
              <Grid.Col span={2}>
                <Text size="sm" align="right">
                  {amountTransaction}
                </Text>
              </Grid.Col>
              <Grid.Col span={3}>
                <PaymentStatusBadge status={status} />
              </Grid.Col>
            </Grid>
          </Grid.Col>
          {succeededAt && <Grid.Col span={6}>{children}</Grid.Col>}

          {!!smallChargeFeeTransaction && (
            <Grid.Col span={6} pt={0} mt={-8}>
              <Grid>
                <Grid.Col span={2} />
                <Grid.Col span={4} />
                <Grid.Col span={2}>
                  <Text size="sm" align="right">
                    {smallChargeFeeTransaction}
                  </Text>
                </Grid.Col>
              </Grid>
            </Grid.Col>
          )}

          {hasChange && (
            <Grid.Col span={6} pt={0} mt={-8}>
              <Grid>
                <Grid.Col span={2} />
                <Grid.Col span={4}>
                  <Text size="sm">Change</Text>
                </Grid.Col>
                <Grid.Col span={2}>
                  <Text size="sm" align="right">
                    {getCurrencyString(change)}
                  </Text>
                </Grid.Col>
              </Grid>
            </Grid.Col>
          )}
        </Grid>
      </Grid.Col>
    </Grid>
  );
};
