import { useState } from 'react';
import { useToast } from './useToast';

export const useUploadFileByPreSignedUrl = () => {
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

  const uploadFileToS3 = async ({
    file,
    preSignedUrl,
  }: {
    file: File | null;
    preSignedUrl: string;
  }) => {
    if (!file) return null;
    try {
      setIsLoading(true);
      const response = await fetch(preSignedUrl, {
        method: 'PUT',
        body: file,
        headers: { 'Content-Type': '' },
      });

      const { origin, pathname } = new URL(response.url);

      return `${origin}${pathname}`;
    } catch (err) {
      if (err instanceof Error)
        toast.error(`Error uploading file: ${err.message}`);
    } finally {
      setIsLoading(false);
    }
  };
  return { uploadFileToS3, isLoading };
};
