import { IconTrash } from '@tabler/icons-react';
import { ActionIcon, Flex, Grid, Text } from '@mantine/core';

import { GiftPackageItems } from './GiftPackageItems';
import {
  GiftPackageProps,
  GiftPackages as GiftPackagesType,
} from '../../../../types';

export const GiftPackages = ({
  giftPackages,
  insertListItem,
  removeListItem,
  setFieldValue,
  getInputProps,
  clearFieldError,
}: GiftPackageProps & GiftPackagesType) => {
  const deleteDisabled = giftPackages.length === 1;

  return (
    <Grid>
      {giftPackages.map(({ name, gift_package_items }, index) => (
        <Grid.Col span={6} key={name}>
          <Flex justify="space-between">
            <Text weight={600} size="lg" mb="sm">
              {name}
            </Text>
            <ActionIcon
              disabled={deleteDisabled}
              onClick={() => removeListItem('gift_packages', index)}
            >
              <IconTrash size={16} />
            </ActionIcon>
          </Flex>
          <GiftPackageItems
            giftPackageIndex={index}
            giftPackageItems={gift_package_items}
            removeListItem={removeListItem}
            insertListItem={insertListItem}
            setFieldValue={setFieldValue}
            getInputProps={getInputProps}
            clearFieldError={clearFieldError}
          />
        </Grid.Col>
      ))}
    </Grid>
  );
};
