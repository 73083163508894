// Auth services
export const AUTH_API_PATH = 'auth';
export const SIGN_IN_PATH = `${AUTH_API_PATH}/login`;
export const GET_REFRESH_TOKEN_PATH = `${AUTH_API_PATH}/refresh`;

export const RESET_PASSWORD_PATH = `reset-password`;

// Upload document
export const UPLOAD_DOCUMENT_PATH = 'document/upload';
export const UPSERT_DOCUMENT_PATH = 'document/upsert';
export const PRIVATE_DOCUMENT_PATH = 'document/private-document-url';
// Get states
export const GET_STATES_PATH = 'states';

// User service
export const USER_API_PATH = 'employee';

export const GET_USER_PROFILE_PATH = `${USER_API_PATH}/me`;

// Location service
export const LOCATION_API_PATH = 'location';
// Local taxes
export const GET_LOCAL_TAXES_PATH = 'local_taxes';

// Role service
export const ROLE_API_PATH = 'role';

// Employee service
export const EMPLOYEE_API_PATH = 'employee';

// Product Category service
export const CATEGORY_API_PATH = 'category';

// Product Skus
export const PRODUCT_SKUS_PATH = 'product';
export const SKUS_PATH = 'stock-keeping-unit';

//Product Vendor
export const PRODUCT_VENDORS_PATH = 'vendor';

// Product Inventory
export const PRODUCT_INVENTORY_PATH = 'inventory';

// Partner
export const PARTNER_PATH = 'partner';

export const PRODUCT_INVENTORY_LOCATION_PATH = `${PRODUCT_INVENTORY_PATH}-location`;
export const PRODUCT_INVENTORY_LIST_PATH = `${PRODUCT_INVENTORY_PATH}-item`;
export const PRODUCT_INVENTORY_TRANSFER_PATH = 'transfer';

// Inventory Adjustment
export const INVENTORY_ADJUSTMENT_PATH = 'adjustments';

// Inventory Library
export const INVENTORY_LIBRARY_PATH = 'library';

//Culinary
export const PRODUCT_CULINARY = 'product-culinary';
export const CULINARY_MENU = 'culinary-menus';

//Customer
export const CUSTOMER_PATH = 'customer';

export const SHIPMENT_CYCLES_PATH = 'shipment-cycles';

//Discounts
export const DISCOUNTS_PATH = 'discounts';

//Orders
export const SALES_ORDERS = 'sales-orders';
export const SALES_ORDER_ITEMS = 'sales-order-items';

//Shipment sales order
export const SHIPMENT_SALES_ORDERS_PATH = 'shipment-sales-orders';
export const SHIPMENT_CYCLE_SALES_ORDERS_PATH = 'shipment-cycle-sales-orders';
export const SHIPMENT_SALES_ORDER_COMPLIANCE_HISTORY_PATH =
  'shipment-sales-order-compliance-history';

//Corporate gift sales orders
export const CORPORATE_GIFT_SALES_ORDERS_PATH = 'corporate-gift-sales-orders';

export const POS_SALES_ORDERS = 'pos-sales-order';
export const PAYMENTS = 'payment';

// Verify address google api
export const ADDRESS_VALIDATION_PATH =
  'https://addressvalidation.googleapis.com/v1:validateAddress?key';
