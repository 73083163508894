import { Link, useNavigate } from 'react-router-dom';
import { BackButton, ContentLayout } from 'components';
import { useEditSuborder } from '../../hooks';
import { EditSuborderForm } from '../form';

export const EditSuborder = () => {
  const navigate = useNavigate();
  const {
    order,
    isLoading,
    initialValues,
    summary,
    defaultClubMembership,
    giftPackageName,
  } = useEditSuborder();

  const title = `Order ${order?.number}`;

  return (
    <ContentLayout
      title={title}
      isLoading={isLoading}
      leftAction={<BackButton component={Link} onClick={() => navigate(-1)} />}
    >
      <EditSuborderForm
        initialValues={initialValues}
        order={order}
        summary={summary}
        giftPackageName={giftPackageName}
        defaultClubMembership={defaultClubMembership}
      />
    </ContentLayout>
  );
};
