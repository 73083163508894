import { Fragment } from 'react';
import { useToggle } from '@mantine/hooks';
import { openModal } from '@mantine/modals';
import { Button, Flex, Image, Loader, Modal, Text } from '@mantine/core';
import { PartialReceiptModal } from 'components/orders/partialReceiptModals';
import { RefundModals } from 'components/orders/refundModals';
import {
  Payer,
  PaymentMethod,
  PaymentStatus,
  PosSalesPaymentType,
  RefundItemRequest,
  RefundType,
  OrderPaymentStatus,
  SendReceiptPayload,
  getCurrencyString,
  getFullName,
} from 'shared';
import { useCalculateRefundItems, usePrivateDocument } from 'hooks';

type Props = {
  transactionId: string;
  paymentId: string;
  type?: PosSalesPaymentType;
  paymentStatus: PaymentStatus;
  s3ReceiptPath: string | null;
  toRefund: number;
  signatureUrl: string;
  payer: Payer | null;
  payerName: string;
  method: PaymentMethod;
  paymentMethod?: string;
  isOrderRefunded?: boolean;
  orderNumber?: string;
  isCorrection?: boolean;
  isRefunding?: boolean;
  isRefunded?: boolean;
  isCycle?: boolean;
  generationPDFIds?: string[];
  productItems?: {
    id: string;
    quantity: number;
    payment_status: OrderPaymentStatus;
  }[];
  handleRefund?: ({
    type,
    paymentData,
  }: {
    type: RefundType;
    paymentData: {
      payment_id: string;
      items: RefundItemRequest[];
    };
  }) => void;
  handleSendReceipt?: (data: SendReceiptPayload) => void;
  handleRegenerateReceipt?: (id: string) => void;
};

export const PaymentActions = ({
  toRefund,
  paymentId,
  transactionId,
  type,
  paymentStatus,
  s3ReceiptPath,
  payer,
  payerName,
  method,
  paymentMethod,
  orderNumber,
  signatureUrl,
  generationPDFIds = [],
  isCorrection = false,
  isOrderRefunded = false,
  isRefunding = false,
  isRefunded = false,
  isCycle = false,
  productItems = [],
  handleRefund = () => null,
  handleSendReceipt = () => null,
  handleRegenerateReceipt = () => null,
}: Props) => {
  const [value, toggle] = useToggle();
  const {
    isLoading: isLoadingSignature,
    document: signature,
    getDocument,
  } = usePrivateDocument();
  const {
    summary,
    refundItems,
    isLoadingRefundItemCost,
    getCalculate,
    handleCalculateRefund,
  } = useCalculateRefundItems();

  const isGenerationReceipt = generationPDFIds?.includes(transactionId);
  const payerFullName = payer ? getFullName(payer) : payerName;
  const toRefundTransaction = getCurrencyString(toRefund);

  const openReceiptModal = () => {
    openModal({
      size: 'lg',
      styles: { title: { width: '100%' } },
      title: (
        <Text size="md" align="center" weight={600}>
          Get Receipt
        </Text>
      ),
      children: (
        <PartialReceiptModal
          name={payerFullName}
          receiptPath={s3ReceiptPath}
          orderNumber={orderNumber}
          handleSubmit={handleSendReceipt}
          payer={payer}
        />
      ),
    });
  };

  return (
    <Fragment>
      <Flex justify="flex-end" gap="sm">
        {method === PaymentMethod.card &&
          !isRefunded &&
          !isCycle &&
          signatureUrl && (
            <Button
              variant="subtle"
              onClick={() => {
                getDocument(signatureUrl);
                toggle();
              }}
            >
              See Signature
            </Button>
          )}
        {paymentStatus !== PaymentStatus.refunded &&
          !isRefunded &&
          !isOrderRefunded &&
          !isCorrection && (
            <RefundModals
              paymentId={paymentId}
              paymentType={type}
              orderNumber={orderNumber}
              name={payerFullName}
              refundItems={refundItems}
              summaryRefund={summary}
              handleCalculateRefund={handleCalculateRefund}
              toRefundTransaction={toRefundTransaction}
              paymentMethod={paymentMethod}
              isRefunding={isRefunding}
              isLoadingRefundItemCost={isLoadingRefundItemCost}
              productItems={productItems}
              getCalculate={getCalculate}
              handleRefund={handleRefund}
            />
          )}
        {s3ReceiptPath ? (
          <Button onClick={openReceiptModal}>Get Receipt</Button>
        ) : (
          <Button
            loading={isGenerationReceipt}
            onClick={() => handleRegenerateReceipt(paymentId)}
          >
            Generate Receipt
          </Button>
        )}
      </Flex>

      <Modal centered opened={value} onClose={toggle}>
        <Flex justify="center" align="center" sx={{ minHeight: 300 }}>
          {isLoadingSignature ? (
            <Loader size={40} />
          ) : (
            <Image
              width={100}
              src={signature?.url}
              alt={signature?.document_name}
            />
          )}
        </Flex>
      </Modal>
    </Fragment>
  );
};
