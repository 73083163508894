import { useMemo } from 'react';
import { omit } from 'rambda';
import { useApiMutation, useToast } from 'hooks';
import {
  ClubMembershipChangeReason,
  ClubMembershipValues,
  ProfilePayload,
  dateInputOutputFormat,
  getCreatePath,
  getSelectOptions,
} from 'shared';
import { CustomerService } from 'App/api';
import { useCustomer } from './useCustomer';
import {
  getClubMemberPayload,
  getClubMemberValues,
  normalizeCustomerErrors,
  normalizeProfile,
} from '../helpers';
import { generateRoutePath, RouteName } from 'App/routes';

const CUSTOMER_KEY = 'customer';

type Props = {
  id: string;
};

export const useEditCustomer = ({ id }: Props) => {
  const { data, isLoading, isError, refetch } = useCustomer(id);
  const addToast = useToast();

  const { mutate: updateCustomerProfile, isLoading: isUpdatingProfile } =
    useApiMutation([CUSTOMER_KEY, id], CustomerService.updateProfile, {
      onSuccess: () => {
        refetch();
        addToast.success('Profile is Updated!');
      },
    });

  const { mutate: updateClubMembership, isLoading: isUpdatingClubMembership } =
    useApiMutation(
      ['updateClubMembership', id],
      CustomerService.updateClubMembership,
      {
        onSuccess: () => {
          refetch();
          addToast.success('Club Membership is Updated!');
        },
      },
    );

  const { mutate: resetPassword, isLoading: isSendingPassword } =
    useApiMutation(['resetPassword', id], CustomerService.resetPassword, {
      onSuccess: () => {
        refetch();
        addToast.success(
          'The link has been sent successfully, check your email!',
        );
      },
    });

  const { profile, club_membership, addresses, errors } = data ?? {};

  const submitClubMembership = ({
    value,
    reason,
    update_orders,
    customer_source_id,
  }: {
    value: ClubMembershipValues;
    reason?: ClubMembershipChangeReason | null;
    customer_source_id?: string | null;
    update_orders?: boolean;
  }) => {
    const payload = getClubMemberPayload(value);

    updateClubMembership({
      customer_id: id,
      club_membership_id: club_membership?.id ?? '',
      update_orders,
      data: {
        ...payload,
        reason: reason ?? null,
        customer_source_id: customer_source_id ?? null,
      },
    });
  };

  const submitProfile = (profile: ProfilePayload) => {
    const payload = {
      id,
      data: {
        ...omit(['lifetime_value'], profile),
        date_of_birth: dateInputOutputFormat(
          profile.date_of_birth as string,
          'output',
        ),
      },
    };

    updateCustomerProfile(payload);
  };

  const initialProfileValues = useMemo(
    () => profile && normalizeProfile(profile),
    [profile],
  );

  const initialClubMemberValues = useMemo(
    () => club_membership && getClubMemberValues(club_membership),
    [club_membership],
  );

  const addressOptions = useMemo(() => {
    if (!addresses) return [];
    return getSelectOptions(
      addresses.map(({ address_name, id }) => ({
        name: address_name || 'N/A',
        id: id as string,
      })),
    );
  }, [addresses]);

  const customerErrors = useMemo(
    () => normalizeCustomerErrors({ errors }),
    [errors],
  );

  const salesOrderPath = generateRoutePath({
    name: RouteName.SalesOrders,
  });
  const createSalesOrderPath = getCreatePath(salesOrderPath);

  const goToCreateOrderProps = () => {
    const to = createSalesOrderPath;
    const state = data;

    return { to, state };
  };

  return {
    customerErrors,
    initialProfileValues,
    data,
    isLoading,
    isError,
    isUpdatingProfile,
    isUpdatingClubMembership,
    isSendingPassword,
    addressOptions,
    initialClubMemberValues,
    refetch,
    submitProfile,
    resetPassword,
    submitClubMembership,
    goToCreateOrderProps,
  };
};
