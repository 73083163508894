import { useForm } from '@mantine/form';
import {
  Button,
  Container,
  Flex,
  Grid,
  Group,
  Select,
  Text,
} from '@mantine/core';
import { ConfirmModal, GeneralInfo, SummaryOrder } from 'components';
import {
  ClubMemberShip,
  CorporateOrderPayload,
  OrderPaymentStatus,
  OrderStatus,
  SelectOption,
} from 'shared';
import { InventoryDetailsSubOrders } from '../inventoryDetailsSubOrders';

type Props = {
  initialValues?: CorporateOrderPayload;
  rootPath: string;
  userCards?: SelectOption[];
  threeDSecureUrl?: string;
  orderTotalPrice?: string;
  summary?: [string, string][];
  isProcessingApplied: boolean;
  isPaying: boolean;
  isDeleting: boolean;
  isRefunding: boolean;
  defaultClubMemberShip: ClubMemberShip | null;
  promocode: JSX.Element;
  handleConfirmAndPay: () => void;
  handleRefund: () => void;
  handleDelete: () => void;
  handleUpdateOrder: (data: CorporateOrderPayload) => void;
};

export const EditForm = ({
  rootPath,
  initialValues,
  userCards,
  threeDSecureUrl,
  summary,
  orderTotalPrice,
  defaultClubMemberShip,
  promocode,
  isPaying,
  isDeleting,
  isRefunding,
  isProcessingApplied,
  handleConfirmAndPay,
  handleRefund,
  handleDelete,
  handleUpdateOrder,
}: Props) => {
  const form = useForm({
    initialValues,
  });

  const { values, isDirty, onSubmit } = form;

  const { id, status, number, payment_status } = initialValues ?? {};

  const isPaid = payment_status === OrderPaymentStatus.PAID;

  const completePayment =
    payment_status &&
    [
      OrderPaymentStatus.PAID,
      OrderPaymentStatus.REFUND,
      OrderPaymentStatus.PROCESSING_PAYMENT,
    ].includes(payment_status);

  const disabled =
    (status && status === OrderStatus.CLOSED) ||
    isProcessingApplied ||
    completePayment;

  const defaultCard = userCards?.find(
    ({ value }) => values.customer_card_id === value,
  )?.label;

  const confirmAndPayDescription = `You are about to pay for the order #${number} using ${defaultCard}`;
  const confirmAndPayTitle =
    'Are you sure you want to pay this Corporate Order?';

  const confirmDeleteDescription = `Are you sure you want to delete order #${number}? `;
  const confirmDeleteTitle = 'Delete Order?';

  return (
    <form
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          e.preventDefault();
        }
      }}
      onSubmit={onSubmit(handleUpdateOrder)}
    >
      <GeneralInfo<CorporateOrderPayload>
        form={form}
        clubMemberShip={defaultClubMemberShip}
      />
      <InventoryDetailsSubOrders parentOrderId={id} rootPath={rootPath} />
      {summary?.map((i) => <SummaryOrder key={i[0]} item={i} />)}
      <Grid mt={85}>
        <Grid.Col>
          <Text weight={600} size="lg" mb="sm">
            Payment Information
          </Text>
        </Grid.Col>
        <Grid.Col span={4}>
          <Select
            required
            label="Payment Method"
            data={userCards}
            {...form.getInputProps('customer_card_id')}
          />
        </Grid.Col>
        <Grid.Col span={4} mt={28}>
          {orderTotalPrice}
        </Grid.Col>
        {isPaid && (
          <Grid.Col span={4} mt={28}>
            <Flex justify="flex-end">
              <Button onClick={handleRefund} loading={isRefunding}>
                Refund
              </Button>
            </Flex>
          </Grid.Col>
        )}
        {threeDSecureUrl && !isPaid && (
          <Grid.Col span={4} mt={28}>
            <Flex justify="flex-end">
              <Button href={threeDSecureUrl} component="a">
                Confirm Three D Secure
              </Button>
            </Flex>
          </Grid.Col>
        )}
      </Grid>
      {promocode}
      <Container size="xs" px={0}>
        <Group mt={48}>
          <Button fullWidth type="submit" disabled={!isDirty() || disabled}>
            Save
          </Button>

          <ConfirmModal
            fullWidth
            type="button"
            variant="outline"
            modalSize="lg"
            label="Confirm and Pay"
            loading={isPaying}
            disabled={disabled}
            title={confirmAndPayTitle}
            onConfirm={handleConfirmAndPay}
          >
            <Text>{confirmAndPayDescription}</Text>
            <Text size="lg" weight={600} mt={10}>
              {orderTotalPrice}
            </Text>
          </ConfirmModal>

          <ConfirmModal
            fullWidth
            type="button"
            variant="white"
            modalSize="lg"
            label="Delete Order"
            loading={isDeleting}
            title={confirmDeleteTitle}
            onConfirm={handleDelete}
          >
            <Text>{confirmDeleteDescription}</Text>
          </ConfirmModal>
        </Group>
      </Container>
    </form>
  );
};
