import { Button, Grid, Text } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { CorporateOrderPayload } from 'shared';
import { getNewPackage } from '../../../../helper';
import { GiftPackages } from './GiftPackages';

type Props = {
  form: UseFormReturnType<
    CorporateOrderPayload,
    (values: CorporateOrderPayload) => CorporateOrderPayload
  >;
};

export const WineSelection = ({ form }: Props) => {
  const {
    values,
    insertListItem,
    removeListItem,
    setFieldValue,
    getInputProps,
    clearFieldError,
  } = form;

  const { gift_packages } = values;

  const isDisabledAddPackages = gift_packages.length === 10;

  return (
    <Grid>
      <Grid.Col>
        <Text weight={600} size="lg" mb="sm">
          Wine Selection
        </Text>
      </Grid.Col>

      <Grid.Col>
        <GiftPackages
          giftPackages={gift_packages}
          insertListItem={insertListItem}
          removeListItem={removeListItem}
          setFieldValue={setFieldValue}
          getInputProps={getInputProps}
          clearFieldError={clearFieldError}
        />
      </Grid.Col>

      <Grid.Col>
        <Button
          p="0"
          mb="24px"
          variant="white"
          disabled={isDisabledAddPackages}
          onClick={() => {
            const index = gift_packages.length - 1;
            const nextIndex =
              Number(gift_packages[index].name.replace(/\D/g, '')) + 1;

            insertListItem('gift_packages', getNewPackage(nextIndex));
          }}
        >
          + Gift Package
        </Button>
      </Grid.Col>
    </Grid>
  );
};
