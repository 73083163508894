import { Button, Container, Text } from '@mantine/core';
import { openModal } from '@mantine/modals';
import { Card, CardAction, CardSkeleton } from '../../common';
import { getChangeInformationMessage } from '../../../helpers';
import { PAYMENT_METHOD } from '../../../constants';
import { useEditWallet } from './hooks';
import { AddCard } from './AddCard';
import { useCustomerOrders } from 'pages/crm/customers/hooks';
import { AddressDto, OrderPaymentStatus } from 'shared';
import { ConfirmData } from 'components';
import { EditCard } from './EditCard';

type Props = {
  customerId: string;
  defaultAddress?: AddressDto;
  canEdit?: boolean;
  errors: JSX.Element;
  refetch: () => void;
};

export const WalletTab = ({
  customerId,
  defaultAddress,
  canEdit,
  errors,
  refetch,
}: Props) => {
  const {
    cards,
    editCardData,
    isForm,
    isCreating,
    isUpdating,
    isLoadingCards,
    setIsForm,
    handleUpdateBillingAddress,
    handleCreateCard,
    deleteCard,
    setDefaultCard,
    setEditCardData,
  } = useEditWallet({
    id: customerId,
    refetchCustomer: refetch,
  });

  const defaultCard = cards?.find((card) => card.isDefault);
  const { brand, last4, stripe_card_id } = defaultCard ?? {};

  const { orders, isLoading: isLoadingOrders } = useCustomerOrders({
    customerId,
    data: stripe_card_id ? { card: { brand, last4, stripe_card_id } } : {},
    paymentStatus: OrderPaymentStatus.UNPAID,
    enabled: !isLoadingCards,
  });
  const isUnpaid = !!orders?.items.length;

  if (editCardData) {
    return (
      <EditCard
        initialValues={editCardData}
        isLoading={isUpdating}
        handleSubmit={handleUpdateBillingAddress}
        goBack={() => setEditCardData(null)}
      />
    );
  }

  if (isForm)
    return (
      <AddCard
        defaultAddress={defaultAddress}
        isUnpaid={isUnpaid}
        isCreating={isCreating}
        setIsForm={setIsForm}
        createCard={(wallet, update_orders) =>
          handleCreateCard({ wallet, update_orders })
        }
      />
    );

  return (
    <Container size="sm">
      {errors}

      {isLoadingCards || isLoadingOrders ? (
        <CardSkeleton />
      ) : (
        cards?.map(
          ({
            id,
            cardName,
            isDefault,
            streetAddress,
            numberCard,
            billing_address,
          }) => (
            <Card
              key={id}
              title={cardName}
              isDefault={isDefault}
              description={streetAddress}
              subDescriptions={numberCard}
            >
              <CardAction
                editAction={() =>
                  setEditCardData({
                    idCard: id,
                    cardName,
                    customerId,
                    ...billing_address,
                  })
                }
                {...(cards.length > 1
                  ? {
                      deleteAction: () =>
                        deleteCard({ id: customerId, idCard: id }),

                      defaultAction: () => {
                        if (isUnpaid) {
                          return openModal({
                            size: 'lg',
                            title: (
                              <Text size="md" weight={600}>
                                Update Upcoming Order?
                              </Text>
                            ),
                            children: (
                              <ConfirmData
                                message={getChangeInformationMessage(
                                  PAYMENT_METHOD,
                                )}
                                approve={() =>
                                  setDefaultCard({
                                    id: customerId,
                                    idCard: id,
                                    update_orders: true,
                                  })
                                }
                                reject={() =>
                                  setDefaultCard({ id: customerId, idCard: id })
                                }
                              />
                            ),
                          });
                        }

                        setDefaultCard({ id: customerId, idCard: id });
                      },
                    }
                  : {})}
              />
            </Card>
          ),
        )
      )}

      <Button
        variant="white"
        disabled={!canEdit}
        onClick={() => setIsForm(true)}
      >
        + Add Another Card
      </Button>
    </Container>
  );
};
