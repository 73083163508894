import { Button, Container, Text } from '@mantine/core';
import { openModal } from '@mantine/modals';
import { IconPlus } from '@tabler/icons-react';

import {
  displayAddressDescription,
  getChangeInformationMessage,
} from '../../../helpers';
import {
  DELETE_DEFAULT_ADDRESS_TEXT,
  SHIPPING_ADDRESS,
} from '../../../constants';
import { Card, CardAction, CardSkeleton } from '../../common';
import { useEditAddress } from './hooks';
import { EditAddress } from './EditAddress';
import {
  AddressDto,
  CustomerDto,
  OrderPaymentStatus,
  dateInputOutputFormat,
} from 'shared';
import { AddAddressForm } from '../../forms/AddAddressForm';
import { useCustomerOrders } from 'pages/crm/customers/hooks';
import { ConfirmData } from 'components';

type Props = {
  customerId?: string;
  profile?: CustomerDto['profile'];
  canEdit?: boolean;
  errors: JSX.Element;
  refetch: () => void;
};

export const AddressesTab = ({
  customerId,
  profile,
  canEdit,
  errors,
  refetch,
}: Props) => {
  const {
    addresses,
    isLoading,
    isAddAddress,
    setIsAddAddress,
    addAddress,
    deleteAddress,
    openedVerifyAddressModal,
    close,
    setIsNotVerify,
    editAddressValues,
    setEditAddressValues,
    handleSubmitEdit,
    deleteAndMakeDefaultNextAddress,
  } = useEditAddress({
    customerId,
    refetch,
  });

  const defaultAddress = addresses?.find((address) => address.is_default);

  const { orders, isLoading: isLoadingOrders } = useCustomerOrders({
    customerId,
    data: defaultAddress?.zip_code
      ? {
          address: {
            ...defaultAddress,
            first_name: defaultAddress.first_name ?? '',
            last_name: defaultAddress.last_name ?? '',
            date_of_birth: dateInputOutputFormat(
              defaultAddress.date_of_birth,
              'output',
            ),
          },
        }
      : {},
    paymentStatus: OrderPaymentStatus.UNPAID,
    enabled: !isLoading,
  });
  const isUnpaid = !!orders?.items.length;

  if (editAddressValues)
    return (
      <EditAddress
        initialValues={editAddressValues}
        handleSubmit={(values: AddressDto) => {
          handleSubmitEdit({
            id: customerId,
            addressId: editAddressValues.id,
            data: values,
            isUpdate: isUnpaid && editAddressValues.is_default,
          });
        }}
        isUnpaid={isUnpaid}
        processing={isLoading}
        opened={openedVerifyAddressModal}
        close={close}
        setIsNotVerify={setIsNotVerify}
        goBack={() => setEditAddressValues(null)}
      />
    );

  if (isAddAddress)
    return (
      <AddAddressForm
        isUnpaid={isUnpaid}
        isLoading={isLoading}
        profile={profile}
        handleSubmit={addAddress}
        setIsNotVerify={setIsNotVerify}
        opened={openedVerifyAddressModal}
        goBack={() => setIsAddAddress(false)}
        close={close}
      />
    );

  return (
    <Container size="sm">
      {errors}
      {isLoading || isLoadingOrders ? (
        <CardSkeleton />
      ) : (
        addresses?.map((address) => {
          const { address_name, id, is_default } = address;
          const description = displayAddressDescription(address);
          return (
            <Card
              title={address_name || 'Unknown'}
              description={description}
              key={address.id}
              isDefault={address.is_default}
            >
              {canEdit && (
                <CardAction
                  editAction={() => setEditAddressValues(address)}
                  {...(!address.is_default && {
                    defaultAction: () => {
                      if (isUnpaid) {
                        return openModal({
                          size: 'lg',
                          title: (
                            <Text
                              data-testid="update-upcoming-order-text"
                              size="md"
                              weight={600}
                            >
                              Update Upcoming Order?
                            </Text>
                          ),
                          children: (
                            <ConfirmData
                              message={getChangeInformationMessage(
                                SHIPPING_ADDRESS,
                              )}
                              approve={() =>
                                handleSubmitEdit({
                                  id: customerId,
                                  addressId: id,
                                  data: { ...address, is_default: true },
                                  isUpdate: true,
                                })
                              }
                              reject={() =>
                                handleSubmitEdit({
                                  id: customerId,
                                  addressId: id,
                                  data: { ...address, is_default: true },
                                  isUpdate: false,
                                })
                              }
                            />
                          ),
                        });
                      }

                      handleSubmitEdit({
                        id: customerId,
                        addressId: id,
                        data: { ...address, is_default: true },
                        isUpdate: false,
                      });
                    },
                  })}
                  {...(addresses.length > 1 && {
                    deleteAction: () => {
                      if (isUnpaid && is_default) {
                        const newDefaultAddress = addresses?.filter(
                          (i) => i.id !== id,
                        )[0];

                        return openModal({
                          size: 'lg',
                          title: (
                            <Text
                              data-testid="update-upcoming-order-text"
                              size="md"
                              weight={600}
                            >
                              Update Upcoming Order?
                            </Text>
                          ),
                          children: (
                            <ConfirmData
                              message={DELETE_DEFAULT_ADDRESS_TEXT}
                              approve={() => {
                                deleteAndMakeDefaultNextAddress({
                                  id: customerId,
                                  addressId: newDefaultAddress.id,
                                  data: {
                                    ...newDefaultAddress,
                                    is_default: true,
                                  },
                                  isUpdate: true,
                                  deleteAddressId: id,
                                });
                              }}
                              reject={() =>
                                deleteAndMakeDefaultNextAddress({
                                  id: customerId,
                                  addressId: newDefaultAddress.id,
                                  data: {
                                    ...newDefaultAddress,
                                    is_default: true,
                                  },
                                  isUpdate: false,
                                  deleteAddressId: id,
                                })
                              }
                            />
                          ),
                        });
                      } else {
                        deleteAddress({ id: customerId, addressId: id });
                      }
                    },
                  })}
                />
              )}
            </Card>
          );
        })
      )}
      <Button
        variant="subtle"
        p="0"
        disabled={!canEdit}
        onClick={() => setIsAddAddress(true)}
      >
        <IconPlus />
        <Text
          data-testid="add-another-address-btn"
          weight={600}
          size="sm"
          ml={10}
        >
          Add Another Address
        </Text>
      </Button>
    </Container>
  );
};
