import { Fragment } from 'react';
import { Divider } from '@mantine/core';
import {
  CorrectionOrder,
  LinkOrderOptions,
  Payments,
  RefundItemRequest,
  RefundType,
  OrderPaymentStatus,
  SendReceiptPayload,
  TransactionType,
  getPaymentMethod,
  getPaymentSummary,
  normalizePaymentItemsTable,
} from 'shared';
import {
  InventoryDetailsTable,
  LinkOrders,
  PaymentActions,
  PaymentInformation,
} from 'components';

type PropType = {
  paymentItems: Payments[];
  orderNumber?: string;
  linkOrders?: LinkOrderOptions;
  generationPDFPaymentId: string | null;
  productItems?: {
    id: string;
    quantity: number;
    payment_status: OrderPaymentStatus;
  }[];
  orderStatus: OrderPaymentStatus;
  corrections: CorrectionOrder[];
  isRefunding: boolean;
  handleSendReceipt: (data: SendReceiptPayload) => void;
  handleRegenerateReceipt: (id: string) => void;
  handleRefund: ({
    type,
    paymentData,
  }: {
    type: RefundType;
    paymentData: {
      payment_id: string;
      items: RefundItemRequest[];
    };
  }) => void;
};

export const RefundOrderView = ({
  orderNumber,
  orderStatus,
  paymentItems,
  linkOrders,
  generationPDFPaymentId,
  productItems,
  corrections,
  isRefunding,
  handleRefund,
  handleSendReceipt,
  handleRegenerateReceipt,
}: PropType) => {
  const isOrderRefunded = orderStatus === OrderPaymentStatus.REFUND;

  return (
    <>
      {paymentItems.map(
        ({
          id: paymentId,
          key,
          transactions,
          method,
          card_brand,
          card_last4,
          payer,
          payer_name,
          change,
          s3_receipt_path,
          signature_url,
          to_refund,
          status,
        }) => {
          const paymentMethod = getPaymentMethod({
            method,
            card_brand,
            card_last4,
          });

          return (
            <Fragment key={key}>
              {transactions.map(
                ({
                  id,
                  detalization,
                  succeeded_at,
                  created_at,
                  status: transactionStatus,
                  amount,
                  type,
                  is_correction,
                }) => {
                  const isTransactionRefunded = type === TransactionType.REFUND;

                  const orderProducts = detalization.items.map(
                    normalizePaymentItemsTable,
                  );
                  const summary = getPaymentSummary(detalization);

                  return (
                    <Fragment key={id}>
                      {!is_correction && isTransactionRefunded && (
                        <Divider my="sm" />
                      )}
                      {!is_correction && (
                        <InventoryDetailsTable
                          orderProducts={orderProducts}
                          summary={summary}
                          corrections={corrections}
                          isRefunded={isTransactionRefunded}
                        />
                      )}

                      {!isTransactionRefunded && (
                        <LinkOrders linkOrders={linkOrders} />
                      )}
                      <PaymentInformation
                        amount={amount}
                        status={transactionStatus}
                        payer={payer}
                        method={method}
                        paymentMethod={paymentMethod}
                        succeededAt={succeeded_at}
                        createAt={created_at}
                        change={change}
                        isRefunded={isTransactionRefunded}
                      >
                        <PaymentActions
                          isCycle
                          transactionId={id}
                          paymentStatus={status}
                          paymentId={paymentId}
                          orderNumber={orderNumber}
                          productItems={productItems}
                          paymentMethod={paymentMethod}
                          method={method}
                          payer={payer}
                          payerName={payer_name}
                          s3ReceiptPath={s3_receipt_path}
                          signatureUrl={signature_url}
                          toRefund={to_refund}
                          isRefunding={isRefunding}
                          isRefunded={isTransactionRefunded}
                          isOrderRefunded={isOrderRefunded}
                          generationPDFIds={[generationPDFPaymentId ?? '']}
                          handleSendReceipt={handleSendReceipt}
                          handleRefund={handleRefund}
                          handleRegenerateReceipt={handleRegenerateReceipt}
                        />
                      </PaymentInformation>
                    </Fragment>
                  );
                },
              )}
            </Fragment>
          );
        },
      )}
    </>
  );
};
