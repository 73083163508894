import {
  DeliveryMethod,
  FreightCompany,
  FreightMethod,
  Order,
  OrderChannel,
  OrderPaymentStatus,
  OrderSource,
  OrderStatus,
  SupplyType,
} from '../baseOrder';
import { SelectOption } from '../common';
import { AccountGroup, BillingAddress, TireAddonData } from '../customer';
import { SalesOrderComplianceStatus } from '../membersClub';
import { SkuDto } from '../products';

export type CorporateOrderListDto = {
  id: string;
  number: number;
  status: OrderStatus;
  payment_status: OrderPaymentStatus;
  created_at: string;
  customer: {
    id: string;
    number: string;
    first_name: string;
    last_name: string;
    email: string;
    phone_number: string;
    account_group: AccountGroup;
    has_errors: boolean;
    main_tier: TireAddonData | null;
    add_on_tier: TireAddonData | null;
  };
  card: {
    last4: string;
    brand: string;
    stripe_card_id: string;
  };
  total: number;
  sub_orders: number;
};

export type GiftPackage = {
  id?: string;
  name: string;
  gift_package_items: {
    id?: string | null;
    key?: string;
    sku_id?: string;
    sku_name: string;
    quantity: number;
    sku?: SkuDto;
  }[];
};

export enum PromocodeStatus {
  APPLIED = 'applied',
  IN_PROGRESS = 'in_progress',
  NOT_APPLIED = 'not_applied',
  ERROR = 'error',
}

export interface CorporateOrderDto extends Order {
  errors: { id: string; message: string }[];
  billing_address: BillingAddress;
  gift_packages: GiftPackage[];
  recipients_file_url: string | null;
  promocode_data?: { id: string; name: string };
  promocode_status: PromocodeStatus;
}

export type CorporateOrderPayload = {
  id?: string;
  customer: Order['customer'];
  createdAt?: string | null;
  number?: number;
  status: OrderStatus;
  channel: OrderChannel;
  supply_type: SupplyType;
  payment_status: OrderPaymentStatus;
  delivery_method: DeliveryMethod;
  freight_company: FreightCompany;
  freight_method: FreightMethod;
  customer_id: string;
  customer_card_id: string;
  shipping_date: string | Date | null;
  gift_packages: GiftPackage[];
  updated_by_id?: string;
  recipients_file_url: string | null;
  processedIn: OrderSource | null;
  defaultWineAdvisor: SelectOption;
};

export interface CorporateSubOrderList
  extends Omit<
    CorporateOrderListDto,
    'cards' | 'payment_status' | 'total' | 'sub_orders'
  > {
  gift_package: GiftPackage;
  compliance_status: SalesOrderComplianceStatus;
}

export type ProgressCorporateOrder = {
  corporate_gift_sales_order: {
    status: OrderStatus;
  };
  progress: {
    task: string;
    task_status: string;
    task_completion_percentage: number;
  };
};
