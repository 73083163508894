import { useCallback, useMemo } from 'react';
import { isEmpty, reject } from 'rambda';
import { CorporateOrder } from 'App/api';
import { useApiQuery, useQueryParams } from 'hooks';
import { DEFAULT_PAGE_SIZE } from 'shared';
import { normalizeCorporateSubOrdersList } from '../helper';

type RequestFilter = { search_string: string };

export const useCorporateSubOrders = ({ id }: { id?: string }) => {
  const { searchParams, handleSetSearchParams } = useQueryParams({
    page: '1',
    search_string: '',
  });

  const page = Number(searchParams.get('page') ?? 1);
  const search_string = searchParams.get('search_string') ?? '';

  const params = useMemo(() => {
    return {
      page,
      page_size: DEFAULT_PAGE_SIZE,
      search_string,
    };
  }, [page, search_string]);

  const key = JSON.stringify(params);

  const { data, isLoading } = useApiQuery(
    ['getCorporateSubOrders', key, id],
    () =>
      CorporateOrder.getSubCollection({
        id,
        params: reject(isEmpty, params as Record<string, unknown>),
      }),
  );

  const output = useMemo(
    () => data && normalizeCorporateSubOrdersList(data?.items),
    [data],
  );

  const updateFilters = useCallback(
    (data: RequestFilter) => {
      handleSetSearchParams({ page: '1', ...data });
    },
    [handleSetSearchParams],
  );

  return {
    output,
    page,
    search_string,
    isLoading,
    updateFilters,
    handleSetSearchParams,
    totalRecords: data?.total ?? 0,
  };
};
