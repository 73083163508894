import { Button } from '@mantine/core';
import { OrderStatus } from 'shared';

export const BulkButton = ({
  statuses,
  toggle,
}: {
  statuses: OrderStatus;
  toggle: () => void;
}) => {
  if (statuses === OrderStatus.WAITING_PICKUP)
    return (
      <Button variant="white" onClick={toggle}>
        Ship Orders
      </Button>
    );

  return null;
};
