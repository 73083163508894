import * as Yup from 'yup';

import {
  requiredDateValidationRule,
  requiredStringValidationRule,
  requiredNumberValidationRule,
} from '.';
import { DeliveryMethod, OrderChannel } from 'shared/types';

const items = Yup.object().shape({
  sku_id: requiredStringValidationRule('Product Name').nullable(),
  quantity: requiredNumberValidationRule('Qty'),
  key: Yup.string(),
  sku_name: Yup.string(),
  price: Yup.number(),
  discount: Yup.number(),
  total: Yup.number(),
});

const getShippingStringValidation = (label: string) =>
  Yup.string()
    .notRequired()
    .nullable()
    .when('delivery_method', {
      is: DeliveryMethod.SHIPPING,
      then: () => requiredStringValidationRule(label).nullable(),
    });

const getShippingDateValidation = (label: string) =>
  Yup.date()
    .notRequired()
    .nullable()
    .when('delivery_method', {
      is: DeliveryMethod.SHIPPING,
      then: () => requiredDateValidationRule(label),
    });

const getPickupDateValidation = (label: string) =>
  Yup.date()
    .notRequired()
    .nullable()
    .when('delivery_method', {
      is: DeliveryMethod.PICKUP,
      then: () => requiredDateValidationRule(label),
    });

export const getOrderSchema = (isCycleOrder = false) =>
  Yup.object({
    channel: Yup.string(),
    delivery_method: Yup.string(),
    items: Yup.array().of(items),
    supply_type: requiredStringValidationRule('Supply Type'),
    updated_by_id: requiredStringValidationRule(),
    customer_shipping_address_id:
      getShippingStringValidation('Shipping Address'),
    deplete_location_id: requiredStringValidationRule().nullable(),
    deplete_inventory_location_id: getShippingStringValidation(
      'Deplete Inventory Location',
    ),
    pick_up_deadline: getPickupDateValidation('Pickup Deadline'),
    freight_company: getShippingStringValidation('Freight Company'),
    freight_method: getShippingStringValidation('Freight Method'),
    shipping_date: getShippingDateValidation('Shipping Date'),
    shipment_cycle_id: !isCycleOrder
      ? Yup.string()
          .notRequired()
          .nullable()
          .when('channel', {
            is: OrderChannel.WINE_CLUB,
            then: () => requiredStringValidationRule('Wine Club').nullable(),
          })
      : Yup.string().nullable(),
    customer_card_id: requiredStringValidationRule('Card').nullable(),
    re_billing_datetime: isCycleOrder
      ? requiredDateValidationRule('Re-Billing Date')
      : Yup.date().nullable().notRequired(),
    billing_datetime: isCycleOrder
      ? requiredDateValidationRule('Billing Date')
      : Yup.date().nullable().notRequired(),
    is_skipped: Yup.boolean().notRequired(),
  });
