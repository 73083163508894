import { Button, Grid, Text } from '@mantine/core';

import {
  GiftPackageProps,
  GiftPackageItems as GiftPackageItemsType,
} from '../../../../types';
import { getNewProduct } from '../../../../helper';
import { PackageItem } from './PackageItem';

export const GiftPackageItems = ({
  giftPackageIndex,
  giftPackageItems,
  insertListItem,
  removeListItem,
  setFieldValue,
  getInputProps,
  clearFieldError,
}: GiftPackageProps & GiftPackageItemsType) => {
  const deleteDisabled = giftPackageItems.length === 1;
  const giftPackageItemPath = `gift_packages.${giftPackageIndex}.gift_package_items`;

  return (
    <Grid justify="space-between">
      <Grid.Col span={9}>
        <Text size={14} weight={600}>
          Product Name{' '}
          <Text component="span" color="red">
            *
          </Text>
        </Text>
      </Grid.Col>
      <Grid.Col span={2}>
        <Text size={14} weight={600}>
          Qty{' '}
          <Text component="span" color="red">
            *
          </Text>
        </Text>
      </Grid.Col>
      <Grid.Col span={1} />
      {giftPackageItems.map(({ key, sku_id, sku_name }, index) => {
        const defaultValue = { label: sku_name, value: sku_id ?? '' };

        return (
          <PackageItem
            key={key ?? sku_id}
            index={index}
            defaultValue={defaultValue}
            deleteDisabled={deleteDisabled}
            giftPackageItemPath={giftPackageItemPath}
            getInputProps={getInputProps}
            setFieldValue={setFieldValue}
            clearFieldError={clearFieldError}
            removeListItem={removeListItem}
          />
        );
      })}
      <Grid.Col>
        <Button
          p="0"
          variant="white"
          onClick={() => {
            insertListItem(giftPackageItemPath, getNewProduct());
          }}
        >
          + Add Product
        </Button>
      </Grid.Col>
    </Grid>
  );
};
