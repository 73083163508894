import { useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { CustomerService } from 'App/api';
import { useApiQuery } from './useApiQuery';

const key = 'cards';

export const useUserCards = ({ id }: { id?: string }) => {
  const { data, isFetching, isLoading, refetch } = useApiQuery(
    [key, id],
    () => CustomerService.getCards({ id }),
    {
      enabled: !!id,
    },
  );

  const client = useQueryClient();

  const getCardsAsync = useCallback(
    ({ id }: { id?: string }) =>
      client.ensureQueryData({
        queryKey: [key, id],
        cacheTime: 0,
        queryFn: () => CustomerService.getCards({ id }),
      }),
    [client],
  );

  return { data, isFetching, isLoading, refetch, getCardsAsync };
};
