import { useState } from 'react';
import { Box, Button, Flex, Modal, Text } from '@mantine/core';
import { openModal, closeAllModals } from '@mantine/modals';

import {
  FULL_REFUND_TITLE,
  REFUND_BY_ITEM_TITLE,
  REFUND_OPTIONS_TITLE,
} from './constants';
import { RefundItems } from './RefundItems';
import {
  OrderPaymentStatus,
  PosSalesPaymentType,
  RefundItemRequest,
  RefundType,
  RefundedItem,
} from 'shared';
import { SummaryOrder } from '../sections';
import { SummarySkeleton } from './SummarySkeleton';

interface Props {
  paymentId: string;
  toRefundTransaction: string;
  paymentMethod?: string;
  paymentType?: PosSalesPaymentType;
  refundItems: RefundedItem[];
  orderNumber?: string;
  summaryRefund: [string, string][];
  name: string;
  isLoadingRefundItemCost: boolean;
  isRefunding: boolean;
  productItems: {
    id: string;
    quantity: number;
    payment_status: OrderPaymentStatus;
  }[];
  getCalculate: (arg: {
    paymentId: string;
    items: RefundItemRequest[];
  }) => void;
  handleCalculateRefund: ({
    paymentId,
    item,
  }: {
    paymentId: string;
    item: {
      item_id?: string;
      item_quantity: number;
    };
  }) => void;
  handleRefund: ({
    type,
    paymentData,
  }: {
    type: RefundType;
    paymentData: {
      payment_id: string;
      items: RefundItemRequest[];
    };
  }) => void;
}

const normalizeQueryRefundItems = (
  productItems: { id: string; payment_status?: OrderPaymentStatus }[],
) => {
  return productItems
    .filter(
      ({ payment_status }) => payment_status !== OrderPaymentStatus.REFUND,
    )
    .map(({ id }) => ({
      item_id: id,
      item_quantity: 0,
    }));
};

export const RefundModals = ({
  paymentId,
  paymentType,
  toRefundTransaction,
  paymentMethod,
  orderNumber,
  name,
  refundItems,
  summaryRefund,
  productItems,
  isLoadingRefundItemCost,
  isRefunding,
  handleRefund,
  getCalculate,
  handleCalculateRefund,
}: Props) => {
  const [openedRefundByItemModal, setOpenedRefundByItemModal] = useState(false);
  const [openedFullRefundModal, setOpenedFullRefundModal] = useState(false);

  const textFullRefund = `You are about to refund ${toRefundTransaction} to ${paymentMethod}.`;
  const textRefundOptions = `Select the way you want to get the receipt for order #${orderNumber}, ${name}.`;
  const textRefundByItem = `You are about to make a refund to ${paymentMethod}.`;

  const itemsForRefund = refundItems.filter(({ isSelected }) => isSelected);

  const isOtherType = paymentType === PosSalesPaymentType.OTHER;

  const isManyItems =
    (productItems?.length > 1 || productItems[0]?.quantity > 1) && !isOtherType;

  const currentProduct = productItems.filter(
    ({ payment_status }) => payment_status !== OrderPaymentStatus.REFUND,
  );

  const handleRefundModals = (isMany: boolean) =>
    isMany ? openRefundOptionsModal() : setOpenedFullRefundModal(true);

  const openRefundOptionsModal = () =>
    openModal({
      size: 'lg',
      title: (
        <Text size="sm" weight={600}>
          {REFUND_OPTIONS_TITLE}
        </Text>
      ),
      children: (
        <>
          <Text size="sm">{textRefundOptions}</Text>
          <Button
            fullWidth
            onClick={() => {
              closeAllModals();
              setOpenedFullRefundModal(true);
            }}
            mt="md"
          >
            Full Refund
          </Button>
          <Button
            fullWidth
            onClick={() => {
              closeAllModals();
              setOpenedRefundByItemModal(true);

              getCalculate({
                paymentId,
                items: normalizeQueryRefundItems(productItems),
              });
            }}
            mt="md"
          >
            Item Based Refund
          </Button>
        </>
      ),
    });

  return (
    <>
      <Button onClick={() => handleRefundModals(isManyItems)}>Refund</Button>

      <Modal
        size="xl"
        opened={openedRefundByItemModal}
        onClose={() => setOpenedRefundByItemModal(false)}
        title={
          <Text size="sm" weight={600}>
            {REFUND_BY_ITEM_TITLE}
          </Text>
        }
      >
        <Text size="sm">{textRefundByItem}</Text>

        <RefundItems
          paymentId={paymentId}
          numberProducts={currentProduct.length}
          refundItems={refundItems}
          isLoadingRefundItemCost={isLoadingRefundItemCost}
          handleCalculateRefund={handleCalculateRefund}
        />

        <Box sx={{ height: '195px' }}>
          {isLoadingRefundItemCost ? (
            <SummarySkeleton />
          ) : (
            summaryRefund.map((i) => <SummaryOrder key={i[0]} item={i} />)
          )}
        </Box>

        <Flex gap={10} justify="flex-end">
          <Button
            variant="white"
            onClick={() => {
              setOpenedRefundByItemModal(false);
              openRefundOptionsModal();
            }}
          >
            Back
          </Button>
          <Button
            disabled={!itemsForRefund.length}
            loading={isRefunding}
            onClick={() => {
              handleRefund({
                type: RefundType.ITEM_BASED,
                paymentData: {
                  payment_id: paymentId,
                  items: itemsForRefund.map(({ chosen_quantity, item_id }) => ({
                    item_quantity: chosen_quantity,
                    item_id,
                  })),
                },
              });
            }}
          >
            Refund
          </Button>
        </Flex>
      </Modal>

      <Modal
        size="xl"
        opened={openedFullRefundModal}
        onClose={() => setOpenedFullRefundModal(false)}
        title={
          <Text size="sm" weight={600}>
            {FULL_REFUND_TITLE}
          </Text>
        }
      >
        <Text size="sm">{textFullRefund}</Text>
        <Text size="sm">Do you want to proceed?</Text>

        <Flex gap={10} justify="flex-end">
          <Button
            variant="white"
            onClick={() => {
              setOpenedFullRefundModal(false);
              if (isManyItems) {
                openRefundOptionsModal();
              }
            }}
          >
            Back
          </Button>
          <Button
            loading={isRefunding}
            onClick={() => {
              handleRefund({
                type: RefundType.FULL,
                paymentData: {
                  payment_id: paymentId,
                  items: [],
                },
              });
            }}
          >
            Refund
          </Button>
        </Flex>
      </Modal>
    </>
  );
};
