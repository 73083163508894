import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CorporateOrder, OrdersService, PaymentsService } from 'App/api';
import { useApiMutation, useApiQuery, useToast, useUserCards } from 'hooks';
import {
  capitalizeText,
  CorporateOrderPayload,
  formatDate,
  getCheckoutSummary,
  getClubMembership,
  getCurrencyString,
  getFullName,
  getSelectOptions,
  PromocodeStatus,
  RefundType,
  SelectOption,
} from 'shared';
import { getInitialValues } from '../helper';

const key = 'corporateOrder';

const getPromocodeAppliedMessage = (promocodeStatus?: PromocodeStatus) => {
  if (promocodeStatus === PromocodeStatus.ERROR)
    return 'The promo code was not applied!';

  if (promocodeStatus === PromocodeStatus.IN_PROGRESS)
    return 'Please wait a while for the promo code to be applied. The page will be reloaded every 2 minutes until the order is applied';
};

export const useEditCorporateOrder = () => {
  const [promocodeStatus, setPromocodeStatus] =
    useState<PromocodeStatus | null>(null);
  const { id = '' } = useParams();
  const addToast = useToast();

  const {
    data: payment,
    isLoading: isLoadingPayments,
    refetch: refetchPayment,
  } = useApiQuery([`${key}Payment`, id], () =>
    PaymentsService.getCollection(id),
  );

  const {
    data,
    isLoading,
    isFetching,
    refetch: refetchOrder,
  } = useApiQuery([`${key}GetOne`, id], () => CorporateOrder.getOne(id), {
    onSuccess: ({ promocode_status }) => setPromocodeStatus(promocode_status),
    refetchInterval: promocodeStatus === PromocodeStatus.IN_PROGRESS && 120000,
  });

  const { mutate: updateCorporateOrder, isLoading: isUpdating } =
    useApiMutation([`${key}Update`, id], CorporateOrder.update, {
      onSuccess: () => {
        addToast.success('The order has been updated!');
        refetchOrder();
      },
    });

  const { mutate: confirmAndPay, isLoading: isPaying } = useApiMutation(
    [`${key}ConfirmAndPay`, id],
    CorporateOrder.confirmAndPay,
    {
      onSuccess: () => {
        addToast.success('Payment has been added to the schedule!');
        refetchOrder();
        refetchPayment();
      },
    },
  );

  const { mutate: deleteOrder, isLoading: isDeleting } = useApiMutation(
    [`${key}Delete`, id],
    CorporateOrder.delete,
    {
      onSuccess: () => {
        addToast.success('Order has been deleted!');
        refetchOrder();
      },
    },
  );

  const { mutateAsync: refund, isLoading: isRefunding } = useApiMutation(
    [`${key}Refund`, id],
    OrdersService.refund,
    {
      onSuccess: () => {
        refetchOrder();
        refetchPayment();

        addToast.success('Refund has been completed!');
      },
    },
  );

  const {
    number,
    status,
    checkouts,
    errors,
    promocode_data,
    promocode_status,
    customer: { id: customerId, first_name, last_name, phone_number },
  } = data ?? { customer: {} };

  const { data: cards, isLoading: isLoadingCards } = useUserCards({
    id: customerId,
  });

  const defaultCardId = useMemo(() => {
    const { id } =
      cards?.items.find(
        ({ stripe_card_id }) => stripe_card_id === data?.card.stripe_card_id,
      ) ?? {};

    return id;
  }, [cards?.items, data?.card.stripe_card_id]);

  const initialValues = useMemo(
    () => getInitialValues(data, defaultCardId),
    [data, defaultCardId],
  );

  const initialStep = useMemo(
    () => (data?.recipients_file_url ? 1 : 0),
    [data],
  );

  const defaultCustomer = {
    value: customerId ?? '',
    label: `${getFullName({ first_name, last_name })} ${phone_number}`,
  };

  const defaultClubMembership = useMemo(
    () =>
      getClubMembership({
        mainTier: data?.customer.main_tier,
        addOnTier: data?.customer.add_on_tier,
      }),
    [data],
  );

  const userCards = useMemo(() => {
    const cardSelectOptions = cards?.items.map(
      ({ id, card_brand, card_last_4 }) => ({
        id,
        name: `${capitalizeText(card_brand)} **** **** **** ${card_last_4}`,
      }),
    );

    const options = cardSelectOptions && getSelectOptions(cardSelectOptions);
    return options;
  }, [cards]);

  const summary = useMemo(
    () => checkouts?.[0] && getCheckoutSummary(checkouts[0]),
    [checkouts],
  )?.filter((i) => i[0] !== 'Gratuity' && i[0] !== 'Member’s Discount');

  const handleUpdateOrder = (data: CorporateOrderPayload) => {
    const shipping_date = formatDate(data.shipping_date);
    updateCorporateOrder({ id, data: { ...data, shipping_date } });
  };

  const handleConfirmAndPay = () => confirmAndPay({ id });
  const handleDelete = () => deleteOrder({ id });

  const handleRefund = () =>
    refund({ id, data: { refund_type: RefundType.FULL } });

  const promocodeAppliedMessage = getPromocodeAppliedMessage(promocode_status);
  const isProcessingApplied = promocode_status === PromocodeStatus.IN_PROGRESS;

  const defaultPromocode =
    promocode_data &&
    ({
      value: promocode_data.id,
      label: promocode_data.name,
    } as SelectOption);

  const threeDSecureUrl = payment?.items.find(
    ({ three_d_secure_url }) => three_d_secure_url,
  )?.three_d_secure_url;

  return {
    status,
    number,
    defaultCustomer,
    initialStep,
    initialValues,
    userCards,
    summary,
    errors,
    threeDSecureUrl,
    defaultClubMembership,
    promocodeAppliedMessage,
    isProcessingApplied,
    defaultPromocode,
    orderTotalPrice: checkouts && getCurrencyString(checkouts[0]?.total ?? ''),
    isUpdating,
    isPaying,
    isDeleting,
    isRefunding,
    isLoading: isLoading || isFetching || isLoadingCards || isLoadingPayments,
    refetchOrder,
    handleUpdateOrder,
    handleDelete,
    handleRefund,
    handleConfirmAndPay,
  };
};
