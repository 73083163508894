import { Grid, TextInput, Button } from '@mantine/core';
import { IconSearch } from '@tabler/icons-react';
import { useState } from 'react';
import { SEARCH_INPUT } from 'shared';

interface Props {
  handleSubmit: (v: string) => void;
  placeholder?: string;
  value?: string;
  label?: string;
}

export const SearchInput = ({
  handleSubmit,
  label,
  value = '',
  placeholder = 'Search...',
}: Props): JSX.Element => {
  const [text, setText] = useState(value);

  return (
    <Grid align="flex-end" gutter="sm" justify="flex-end">
      <Grid.Col span={8}>
        <TextInput
          data-testid={SEARCH_INPUT}
          label={label}
          value={text}
          placeholder={placeholder}
          icon={<IconSearch stroke={1} size={14} />}
          onChange={({ target: { value } }) => setText(value)}
        />
      </Grid.Col>
      <Grid.Col span="content">
        <Button
          onClick={() => handleSubmit(text)}
          data-testid="search-submit-btn"
        >
          <IconSearch stroke={1} size={24} />
        </Button>
      </Grid.Col>
    </Grid>
  );
};
