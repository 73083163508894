import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { CorporateOrder } from 'App/api';
import { useApiQuery } from 'hooks';
import { OrderStatus } from 'shared';

export const useCorporateOrderProgress = ({
  status,
  handleRefetchOrder,
}: {
  status?: OrderStatus;
  handleRefetchOrder: () => void;
}) => {
  const [corporateOrderProgress, setCorporateOrderProgress] = useState(0);
  const { id = '' } = useParams();

  const isOrderCreationProgress =
    status &&
    [
      OrderStatus.SUBORDERS_CREATION_IS_SCHEDULED,
      OrderStatus.SUBORDERS_CREATION_IN_PROGRESS,
    ].includes(status);

  const hasProcessed = corporateOrderProgress === 100;

  const { data: progressData } = useApiQuery(
    ['getProgress', id],
    () => CorporateOrder.getProgress({ id }),
    {
      enabled: isOrderCreationProgress ?? false,
      onSuccess: ({ corporate_gift_sales_order, progress }) => {
        const hasError =
          corporate_gift_sales_order.status ===
          OrderStatus.SUBORDERS_CREATION_ERROR;

        setCorporateOrderProgress(progress.task_completion_percentage);

        if (progress.task_completion_percentage === 100 || hasError) {
          handleRefetchOrder();
        }
      },

      refetchInterval: !hasProcessed && 5000,
    },
  );

  return { progressData };
};
