import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  useApiMutation,
  useCustomers,
  useToast,
  useUploadFileByPreSignedUrl,
  useUserCards,
} from 'hooks';
import {
  capitalizeText,
  CorporateOrderPayload,
  formatDate,
  getEditPath,
  getFullName,
  getSelectOptions,
  SelectOption,
} from 'shared';
import { CorporateOrder } from 'App/api';
import { useNavigate } from 'react-router-dom';

export const useCreateCorporateOrder = ({
  rootPath = '',
  initialStep,
  initialCustomerId,
  handleRefetchOrder = () => null,
}: {
  rootPath?: string;
  initialStep: number;
  initialCustomerId?: string;
  handleRefetchOrder?: () => void;
}) => {
  const [activeStep, setActiveStep] = useState(initialStep);
  const [userCards, setUserCards] = useState<SelectOption[]>([]);
  const addToast = useToast();
  const navigate = useNavigate();

  const { mutateAsync: createSuborders, isLoading: isCreatingSuborders } =
    useApiMutation(['createCorporateSuborder'], CorporateOrder.createSuborders);

  const { mutate: createCorporateOrder, isLoading: isCreating } =
    useApiMutation(['createCorporateOrder'], CorporateOrder.create, {
      onSuccess: async (data) => {
        if (data.recipients_file_url) {
          await createSuborders({ id: data.id });
        }
        navigate(getEditPath(rootPath, data?.id));
        addToast.success('The order has been created!');
      },
    });

  const { mutate: updateCorporateOrder, isLoading: isUpdating } =
    useApiMutation(['updateCorporateOrder'], CorporateOrder.update, {
      onSuccess: async (data) => {
        if (data.recipients_file_url) {
          await createSuborders({ id: data.id });
        }
        addToast.success('The order has been updated!');
        handleRefetchOrder();
      },
    });

  const { mutateAsync: generatePreSignedUrl, isLoading: isGeneratingUrl } =
    useApiMutation(
      ['generatePreSignedUrl'],
      CorporateOrder.generateS3PreSignedURL,
    );

  const { getCardsAsync } = useUserCards({});
  const { uploadFileToS3, isLoading: isUploadingFile } =
    useUploadFileByPreSignedUrl();

  const {
    output,
    hasMore: hasMoreUsers,
    isLoading: isLoadingUsers,
    setPageSelectAsync: setPageSelectUsers,
    handleSetSearchString: handleSetSearchStringUsers,
  } = useCustomers({
    isSelectAsync: true,
  });

  const getCardByCustomerId = useCallback(
    async (id: string) => {
      const { items } = await getCardsAsync({ id });
      const defaultCardId = items.find(({ is_default }) => is_default)?.id;
      const cards = items.map(({ id, card_brand, card_last_4 }) => ({
        id,
        name: `${capitalizeText(card_brand)} **** **** **** ${card_last_4}`,
      }));
      const options = getSelectOptions(cards);

      setUserCards(options);

      return { defaultCardId };
    },
    [getCardsAsync],
  );

  const handleUploadFile = async ({ file }: { file: File }) => {
    const { name: documentName } = file;

    const { url: preSignedUrl } = await generatePreSignedUrl({
      documentName,
    });

    const url = (await uploadFileToS3({ file, preSignedUrl })) ?? null;

    return url;
  };

  const handleSubmit = async ({
    data,
    isEdit,
    file,
  }: {
    data: CorporateOrderPayload;
    file: File | null;
    isEdit: boolean;
  }) => {
    const { id, shipping_date } = data;
    const shippingDate = formatDate(shipping_date);

    const recipients_file_url = file && (await handleUploadFile({ file }));

    const payload = {
      ...data,
      recipients_file_url,
      shipping_date: shippingDate,
    };

    if (isEdit)
      return updateCorporateOrder({
        id,
        data: payload,
      });

    return createCorporateOrder(payload);
  };

  const userOptions = useMemo(
    () =>
      getSelectOptions(
        output.map(({ id, first_name, last_name, phone_number }) => ({
          id,
          name: `${getFullName({ first_name, last_name })} ${phone_number}`,
        })),
      ),
    [output],
  );

  useEffect(() => {
    if (initialCustomerId) {
      getCardByCustomerId(initialCustomerId);
    }
  }, [getCardByCustomerId, initialCustomerId]);

  return {
    activeStep,
    setActiveStep,
    userCards,
    isProcessing:
      isCreating ||
      isUpdating ||
      isGeneratingUrl ||
      isUploadingFile ||
      isCreatingSuborders,
    isLoadingUsers,
    getCardByCustomerId,
    handleSubmit,
    asyncSelectUsers: {
      hasMore: hasMoreUsers,
      options: userOptions,
      setPageSelectAsync: setPageSelectUsers,
      setSearchStringSelectAsync: handleSetSearchStringUsers,
    },
  };
};
