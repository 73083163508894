import { ReactNode } from 'react';
import { Grid } from '@mantine/core';

export const SearchInputSingleLayout = ({
  children,
}: {
  children: ReactNode;
}) => {
  return (
    <Grid mb="md" justify="space-between">
      <Grid.Col span={5} offset={7}>
        {children}
      </Grid.Col>
    </Grid>
  );
};
