import { IconTrash, IconUpload } from '@tabler/icons-react';
import {
  ActionIcon,
  Button,
  Center,
  Container,
  FileButton,
  Text,
} from '@mantine/core';

import { accept } from '../../../constants';

export const RecipientUpload = ({
  file,
  setFile,
}: {
  file: File | null;
  setFile: React.Dispatch<React.SetStateAction<File | null>>;
}) => {
  return (
    <Container size="xs">
      <Center>
        {file ? (
          <>
            <Text mr={105} weight={600}>
              {file.name}
            </Text>
            <ActionIcon onClick={() => setFile(null)}>
              <IconTrash size={24} stroke={1} />
            </ActionIcon>
          </>
        ) : (
          <FileButton onChange={setFile} accept={accept}>
            {(props) => (
              <Button variant="white" leftIcon={<IconUpload />} {...props}>
                Upload Recipients List
              </Button>
            )}
          </FileButton>
        )}
      </Center>
    </Container>
  );
};
