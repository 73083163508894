import { Box, Button, Text } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import { openModal } from '@mantine/modals';
import {
  PosSalesOrderPayload,
  PosSalesOrderDto,
  SendReceiptPayload,
  getFullName,
  OrderPaymentStatus,
  RefundType,
  RefundItemRequest,
  Payments,
  OrderStatus,
} from 'shared';
import { ActionButtons, ChangeHistory, ConfirmModal } from 'components';
import { posOrderSchema } from './schema';
import { GeneralInfo, SupplyDetails } from './sections';
import { Order } from './sections/order';
import { ReceiptModal } from '../modals';
import { useState } from 'react';

enum Mode {
  EDIT = 'edit',
  CHANGE_HISTORY = 'change history',
}

type PropType = {
  order: PosSalesOrderDto;
  payment: Payments[];
  loading: boolean;
  isRefunding: boolean;
  isGeneratingFullReceipt: boolean;
  generationPDFPaymentIds: string[];
  handleRefund: ({
    type,
    paymentData,
  }: {
    type: RefundType;
    paymentData?: {
      payment_id: string;
      items: RefundItemRequest[];
    };
  }) => void;
  handleSubmit: (values: PosSalesOrderPayload) => void;
  handleSendReceipt: (data: SendReceiptPayload) => void;
  handleGenerateFullReceipt: (id: string) => void;
  handleGeneratePartialReceipt: (id: string) => void;
};

export const Form = ({
  order,
  loading,
  payment,
  isRefunding,
  isGeneratingFullReceipt,
  generationPDFPaymentIds,
  handleRefund,
  handleSubmit,
  handleSendReceipt,
  handleGenerateFullReceipt,
  handleGeneratePartialReceipt,
}: PropType) => {
  const [mode, setMode] = useState<Mode>(Mode.EDIT);
  const {
    id,
    status,
    source,
    number,
    payment_status,
    default_wine_advisor,
    s3_receipt_path,
    pos_order_type,
    deplete_inventory_location,
    is_receipt_generated: isReceipt,
  } = order;

  const defaultWineAdvisor = {
    value: default_wine_advisor?.id,
    label: getFullName({
      first_name: default_wine_advisor?.first_name,
      last_name: default_wine_advisor?.last_name,
    }),
  };
  const form = useForm({
    initialValues: {
      default_wine_advisor_id: default_wine_advisor?.id ?? '',
    },
    validate: yupResolver(posOrderSchema),
    validateInputOnBlur: true,
  });

  const openReceiptModal = () => {
    openModal({
      size: 'lg',
      styles: { title: { width: '100%' } },
      title: (
        <Text size="md" align="center" weight={600}>
          Get Full Receipt
        </Text>
      ),
      children: (
        <ReceiptModal
          payment={payment}
          orderNumber={number}
          receiptPath={s3_receipt_path}
          handleSubmit={handleSendReceipt}
        />
      ),
    });
  };

  if (mode === Mode.CHANGE_HISTORY)
    return <ChangeHistory goBack={() => setMode(Mode.EDIT)} orderId={id} />;

  return (
    <Box>
      <GeneralInfo
        order={order}
        form={form}
        defaultWineAdvisor={defaultWineAdvisor}
      />
      <Order
        order={order}
        payment={payment}
        isRefunding={isRefunding}
        generationPDFPaymentIds={generationPDFPaymentIds}
        handleRefund={handleRefund}
        handleSendReceipt={handleSendReceipt}
        handleGeneratePartialReceipt={handleGeneratePartialReceipt}
      />
      <SupplyDetails order={order} />

      <ActionButtons
        loading={loading}
        disabled={!form.isValid()}
        onClick={() =>
          handleSubmit({
            status,
            source,
            pos_order_type,
            deplete_inventory_location_id: deplete_inventory_location.id,
            default_wine_advisor_id: form.values.default_wine_advisor_id,
          })
        }
        label="Save"
        aria-label="save"
        secondary={
          <>
            {s3_receipt_path ? (
              <Button
                fullWidth
                disabled={!isReceipt}
                onClick={openReceiptModal}
              >
                Get Full Receipt
              </Button>
            ) : (
              <Button
                fullWidth
                loading={isGeneratingFullReceipt}
                onClick={() => handleGenerateFullReceipt(id)}
              >
                Generation Full Receipt
              </Button>
            )}

            {payment_status !== OrderPaymentStatus.REFUND &&
              status !== OrderStatus.CANCEL && (
                <ConfirmModal
                  fullWidth
                  mt="lg"
                  type="button"
                  variant="white"
                  label="Cancel Order"
                  title="Cancel this order?"
                  loading={isRefunding}
                  onConfirm={() =>
                    handleRefund({
                      type: RefundType.FULL,
                    })
                  }
                >
                  <Text>You are about to cancel order {number}.</Text>
                  <Text>Do you want to proceed?</Text>
                </ConfirmModal>
              )}
            <Button
              fullWidth
              mt="lg"
              onClick={() => setMode(Mode.CHANGE_HISTORY)}
            >
              See change history
            </Button>
          </>
        }
      />
    </Box>
  );
};
