import { useMemo, useState } from 'react';

enum FieldLabel {
  'First Name',
  'Last Name',
  'Street Address1',
  'Street Address2',
  'Business',
  'City',
  'State',
  'Zip',
  'Phone',
  'Email',
  'Date of Birth',
  'Gift Package',
}

const notRequiredFields = [3, 4];

const validateRecipientsFile = (recipients: string[][]) => {
  const errors = [] as string[];

  recipients.forEach((row, indexRow) =>
    row.forEach((column, indexColumn) => {
      if (!column.trim() && !notRequiredFields.includes(indexColumn)) {
        errors.push(
          `${FieldLabel[indexColumn]} is a required field in ${indexRow} line`,
        );
      }
    }),
  );

  return errors;
};

const getCsvToArray = (data: File | null): Promise<string[][]> | undefined => {
  if (!data) return;

  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsText(data);
    try {
      reader.onload = async () => {
        const { result } = reader;
        const textByLine = (result as string)?.split('\n');
        const arr = textByLine?.map((i) => i.split(','));
        resolve(arr);
      };

      reader.onerror = function () {
        console.log(reader.error);
      };
    } catch (err) {
      if (err instanceof Error) {
        reject(new Error(err.message));
      }
    }
  });
};

export const useUploadRecipientFile = () => {
  const [file, setFile] = useState<File | null>(null);
  const [errors, setErrors] = useState<string[]>([]);

  const handleRecipients = async (data: File | null) => {
    const recipients = (await getCsvToArray(data)) ?? [];

    setErrors(validateRecipientsFile(recipients));
  };

  useMemo(() => handleRecipients(file), [file]);

  return { file, setFile, errors, setErrors };
};
