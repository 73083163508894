import { Document, Image, Page, Text, View } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';
import HalterRanchBigLogo from 'assets/images/hr-big-logo.png';
import {
  OrderDto,
  getClubMembership,
  getFullName,
  orderPaymentStatusLabels,
  salesOrderSourceTitle,
  salesOrderStatusLabels,
  supplyTypeLabels,
} from 'shared';

const style: Record<string, Style> = {
  page: {
    padding: '25px',
    fontSize: '10px',
    fontWeight: 100,
  },
  image: {
    width: 200,
    height: 'auto',
    margin: '0 auto',
    marginBottom: 15,
  },
  section: {
    padding: '25px 15px',
    borderBottom: '1px solid #000',
  },
  mainText: {
    fontSize: '12px',
    fontWeight: 600,
    marginBottom: 10,
  },
  text: {
    marginBottom: '5px',
  },
  flexSpaceBetween: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
};

export const PdfDocumentOrder = ({ order }: { order: OrderDto }) => {
  const {
    items,
    number,
    created_at,
    status,
    payment_status,
    tracking_number,
    processed_in,
    updated_by,
    supply_type,
    shipping_date,
    shipment_cycle,
    freight_company,
    freight_method,
    shipping_address,
    deplete_location,
    deplete_inventory_location,
    customer,
  } = order ?? {};

  const orderStatus = status && salesOrderStatusLabels[status];
  const paymentStatus =
    payment_status && orderPaymentStatusLabels[payment_status];

  const employee = getFullName({
    first_name: updated_by?.first_name,
    last_name: updated_by?.last_name,
  });

  const { first_name, last_name } = customer;

  const customerName = getFullName({ first_name, last_name });

  const clubMemberShip = getClubMembership({
    mainTier: customer.main_tier,
    addOnTier: customer.add_on_tier,
    shipmentCycle: shipment_cycle,
  });

  return (
    <Document>
      <Page style={style.page}>
        <Image src={HalterRanchBigLogo} style={style.image} />

        <View style={style.section}>
          <Text style={style.mainText}>General Information</Text>
          <View style={{ ...style.flexSpaceBetween, marginBottom: 15 }}>
            <View>
              <Text style={style.text}>Order#</Text>
              <Text style={style.text}>{number}</Text>
            </View>
            <View>
              <Text style={style.text}>Date</Text>
              <Text style={style.text}>{created_at}</Text>
            </View>
            <View>
              <Text style={style.text}>Order status</Text>
              <Text style={style.text}>{orderStatus}</Text>
            </View>
            <View>
              <Text style={style.text}>Payment status</Text>
              <Text style={style.text}>{paymentStatus}</Text>
            </View>
          </View>

          <View style={style.flexSpaceBetween}>
            <View>
              <Text style={style.text}>Updated By</Text>
              <Text style={style.text}>{employee}</Text>
            </View>
            <View>
              <Text style={style.text}>Processed in</Text>
              <Text style={style.text}>
                {processed_in ? salesOrderSourceTitle[processed_in] : '-'}
              </Text>
            </View>
            <View>
              <Text style={style.text}>Customer Name</Text>
              <Text style={style.text}>{customerName}</Text>
            </View>
            <View>
              <Text style={style.text}>Club Membership</Text>
              <Text style={style.text}>{clubMemberShip.text}</Text>
            </View>
          </View>
        </View>
        <View style={style.section}>
          <Text style={style.mainText}>Inventory Details</Text>
          {items.map(({ id, sku_name, quantity }) => (
            <View
              key={id}
              style={{ ...style.flexSpaceBetween, marginBottom: 5 }}
            >
              <Text>Product Name: {sku_name}</Text>
              <Text>Quantity: {quantity}</Text>
            </View>
          ))}
        </View>

        <View style={{ ...style.section, borderBottom: 'none' }}>
          <Text style={style.mainText}>Delivery Information</Text>
          <View style={{ ...style.flexSpaceBetween, marginBottom: 15 }}>
            <View>
              <Text style={style.text}>Supply Type</Text>
              <Text style={style.text}>{supplyTypeLabels[supply_type]}</Text>
            </View>
            <View>
              <Text style={style.text}>Shipping Address</Text>
              <Text style={style.text}>{shipping_address?.address_line_1}</Text>
            </View>
            <View>
              <Text style={style.text}>Primary Location</Text>
              <Text style={style.text}>{deplete_location?.name}</Text>
            </View>
            <View>
              <Text style={style.text}>Inventory Location</Text>
              <Text style={style.text}>
                {deplete_inventory_location?.name ?? '-'}
              </Text>
            </View>
          </View>
          <View style={{ ...style.flexSpaceBetween }}>
            <View>
              <Text style={style.text}>Freight Company</Text>
              <Text style={style.text}>{freight_company}</Text>
            </View>
            <View>
              <Text style={style.text}>Freight Method</Text>
              <Text style={style.text}>{freight_method}</Text>
            </View>
            <View>
              <Text style={style.text}>Shipping Date</Text>
              <Text style={style.text}>{shipping_date}</Text>
            </View>
            <View>
              <Text style={style.text}>Tracking #</Text>
              <Text style={style.text}>{tracking_number ?? '-'}</Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};
