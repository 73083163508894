import { useNavigate } from 'react-router-dom';
import { IconAlertCircle } from '@tabler/icons-react';
import { DataTable } from 'mantine-datatable';
import { Box, Flex } from '@mantine/core';

import { ContentLayout, CreateButton, HintSection } from 'components';
import {
  PROPS_DATA_TABLE,
  salesOrderStatusLabels,
  getCurrencyString,
  salesOrderSourceTitle,
  getEditPath,
  ServiceClass,
  getComplianceStatus,
  orderHintOptions,
  getCreatePath,
} from 'shared';
import { useOrders } from 'hooks';
import { Filters } from '../Filters';

export const List = ({ rootPath }: { rootPath: string }) => {
  const {
    output,
    page,
    statuses,
    payment_statuses,
    search_string,
    totalRecords,
    isLoading,
    updateFilters,
    handleSetSearchParams,
  } = useOrders({ serviceClass: ServiceClass.regular, queryKey: 'sales' });
  const navigate = useNavigate();

  return (
    <ContentLayout
      title="Sales Orders"
      processing={isLoading}
      rightAction={
        <CreateButton to={getCreatePath(rootPath)}>+ New Order</CreateButton>
      }
    >
      <Filters
        values={{ search_string, statuses, payment_statuses }}
        handleChange={updateFilters}
      />
      <DataTable
        {...PROPS_DATA_TABLE}
        page={page}
        onPageChange={(p) => handleSetSearchParams({ page: p.toString() })}
        records={output}
        totalRecords={totalRecords}
        onRowClick={({ id }) => navigate(getEditPath(rootPath, id))}
        columns={[
          {
            accessor: 'compliance_status',
            title: '',
            render: ({ compliance_status }) => {
              return (
                <Box
                  data-testid={compliance_status}
                  sx={{
                    backgroundColor: getComplianceStatus(compliance_status),
                    width: '2px',
                    height: '40px',
                    margin: '-8px -8px',
                  }}
                />
              );
            },
            width: '3px',
          },
          {
            accessor: 'number',
            title: 'Order #',
            width: '9%',
          },
          {
            accessor: 'created_at',
            title: 'Creation Date',
          },
          {
            accessor: 'fully_paid_at',
            title: 'Payment Date',
          },
          {
            accessor: 'customers',
            title: 'Customers',
            render: ({ customer }) =>
              `${customer.first_name} ${customer.last_name}`,
          },
          {
            accessor: 'source',
            title: 'Source',
            render: ({ source }) => salesOrderSourceTitle[source],
          },
          {
            accessor: 'delivery_method',
            title: 'Delivery Method',
          },
          {
            accessor: 'total',
            title: 'Total',
            render: ({ total, is_zero_shipping_tax_fallback_triggered }) => {
              const markAsZero = is_zero_shipping_tax_fallback_triggered ? (
                <IconAlertCircle size="20px" color="#909296" />
              ) : (
                ''
              );

              return (
                <Flex align="center" gap={5}>
                  <Box>{getCurrencyString(total)}</Box>
                  {markAsZero}
                </Flex>
              );
            },
          },
          {
            accessor: 'status',
            title: 'Status',
            render: ({ status }) => salesOrderStatusLabels[status],
          },
        ]}
      />
      <HintSection options={orderHintOptions} />
    </ContentLayout>
  );
};
