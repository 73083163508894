import { SingleValue } from 'react-select';
import { Container, Grid, Select, Text } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { SelectAsync } from 'components';
import {
  AsyncSelect,
  CorporateOrderPayload,
  DATE_FORMAT_SLASH,
  FREIGHT_COMPANY,
  FREIGHT_METHOD,
  SelectOption,
} from 'shared';
import { DatePicker } from '@mantine/dates';
import { WineSelection } from './wineSelection';

type Props = {
  form: UseFormReturnType<
    CorporateOrderPayload,
    (values: CorporateOrderPayload) => CorporateOrderPayload
  >;
  defaultCustomer?: SelectOption;
  asyncSelectUsers: AsyncSelect;
  userCards: SelectOption[] | null;
  isLoadingUsers: boolean;
  getCardByCustomerId: (id: string) => Promise<{
    defaultCardId: string | undefined;
  }>;
};

export const GeneralInfoForm = ({
  form,
  asyncSelectUsers,
  defaultCustomer,
  userCards,
  isLoadingUsers,
  getCardByCustomerId,
}: Props) => {
  const { options, hasMore, setSearchStringSelectAsync, setPageSelectAsync } =
    asyncSelectUsers;

  return (
    <Container size="xl">
      <Grid gutter="xl">
        <Grid.Col span={6}>
          <SelectAsync
            required
            isSearchable
            label="Customer"
            options={options}
            isLoading={isLoadingUsers}
            hasMore={hasMore}
            searchByString={({ search_string }) =>
              setSearchStringSelectAsync(search_string)
            }
            setPage={setPageSelectAsync}
            {...form.getInputProps('customer_id')}
            defaultValue={defaultCustomer}
            onChange={async (item: SingleValue<SelectOption>) => {
              if (item?.value) {
                form.setFieldValue('customer_id', item.value);
                const { defaultCardId } = await getCardByCustomerId(item.value);

                form.setFieldValue('customer_card_id', defaultCardId ?? '');
              }
            }}
          />
        </Grid.Col>
        <Grid.Col span={6}>
          <DatePicker
            placeholder="mm/dd/yyyy"
            label="Estimated Shipment Date"
            inputFormat={DATE_FORMAT_SLASH}
            {...form.getInputProps('shipping_date')}
          />
        </Grid.Col>
        <Grid.Col span={6}>
          <Select
            required
            label="Freight Company"
            data={FREIGHT_COMPANY}
            {...form.getInputProps('freight_company')}
          />
        </Grid.Col>
        <Grid.Col span={6}>
          <Select
            required
            label="Freight Method"
            data={FREIGHT_METHOD}
            {...form.getInputProps('freight_method')}
          />
        </Grid.Col>
      </Grid>
      <WineSelection form={form} />
      <Grid>
        <Grid.Col span={6}>
          <Text weight={600} size="lg" mb="sm">
            Payment Information
          </Text>
          <Select
            required
            label="Payment Method"
            data={userCards}
            {...form.getInputProps('customer_card_id')}
          />
        </Grid.Col>
      </Grid>
    </Container>
  );
};
