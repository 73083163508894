import { useNavigate } from 'react-router-dom';
import { Grid, Text } from '@mantine/core';
import { DataTable } from 'mantine-datatable';
import { SearchInput, SearchInputSingleLayout } from 'components';
import { getSubOrderEditPath, PROPS_DATA_TABLE } from 'shared';
import { subOrderColumns } from '../../constants';
import { useCorporateSubOrders } from '../../hooks';

export const InventoryDetailsSubOrders = ({
  rootPath,
  parentOrderId,
}: {
  rootPath: string;
  parentOrderId?: string;
}) => {
  const navigate = useNavigate();
  const {
    output,
    page,
    isLoading,
    totalRecords,
    search_string,
    updateFilters,
    handleSetSearchParams,
  } = useCorporateSubOrders({
    id: parentOrderId,
  });

  return (
    <Grid mb="xl" gutter="xl">
      <Grid.Col mb="xs">
        <Text size="lg" weight={600}>
          Inventory Details
        </Text>
      </Grid.Col>
      <Grid.Col mb="xs">
        <SearchInputSingleLayout>
          <SearchInput
            label="Search"
            value={search_string}
            handleSubmit={(value: string) =>
              updateFilters({ search_string: value })
            }
          />
        </SearchInputSingleLayout>
        <Grid.Col>
          <DataTable
            {...PROPS_DATA_TABLE}
            page={page}
            records={output}
            totalRecords={totalRecords}
            fetching={isLoading}
            onPageChange={(p) => handleSetSearchParams({ page: p.toString() })}
            onRowClick={({ id: subOrderId, choice }) =>
              navigate(
                getSubOrderEditPath({
                  rootPath,
                  id: parentOrderId ?? '',
                  subOrderId,
                }),
                { state: choice },
              )
            }
            columns={subOrderColumns}
          />
        </Grid.Col>
      </Grid.Col>
    </Grid>
  );
};
