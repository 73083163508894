import { useCallback, useMemo } from 'react';
import { isEmpty, reject } from 'rambda';

import { ShipmentSalesOrdersService } from 'App/api';
import { useApiQuery, useQueryParams } from 'hooks';
import {
  OrderDto,
  DEFAULT_PAGE_SIZE,
  ServiceClass,
  normalizeOrder,
  OrderPaymentStatus,
} from 'shared';

export const useOrders = ({
  serviceClass,
  queryKey,
}: {
  serviceClass: ServiceClass;
  queryKey: 'sales' | 'cycle';
}) => {
  const { searchParams, handleSetSearchParams } = useQueryParams({
    page: '1',
    search_string: '',
  });

  const ORDER_QUERY_KEY = `order-${queryKey}`;
  const isWineClub = serviceClass === ServiceClass.wine_club;

  const page = searchParams.get('page');
  const search_string = searchParams.get('search_string');
  const statuses = searchParams.getAll('statuses');
  const payment_statuses = searchParams.getAll('payment_statuses');

  const params = useMemo(() => {
    return {
      page,
      statuses,
      search_string,
      payment_statuses:
        !isWineClub && !payment_statuses.length
          ? [
              OrderPaymentStatus.PAID,
              OrderPaymentStatus.REFUND,
              OrderPaymentStatus.PARTIALLY_REFUND,
              OrderPaymentStatus.ERROR,
              OrderPaymentStatus.THREE_D_SECURE_CONFIRMATION,
              OrderPaymentStatus.UNPAID,
            ]
          : payment_statuses,
      service_class: ServiceClass[serviceClass],
      page_size: DEFAULT_PAGE_SIZE,
    };
  }, [
    page,
    statuses,
    search_string,
    isWineClub,
    payment_statuses,
    serviceClass,
  ]);

  const key = JSON.stringify(params);

  const { data, isLoading } = useApiQuery(
    [ORDER_QUERY_KEY, key],
    () =>
      ShipmentSalesOrdersService.getCollection(
        reject(isEmpty, params as Record<string, unknown>),
      ),
    { ...(isWineClub ? { cacheTime: 0 } : {}) },
  );

  const output: OrderDto[] = useMemo(() => {
    if (!data) {
      return [];
    }
    return data.items.map(normalizeOrder);
  }, [data]);

  const updateFilters = useCallback(
    (data: { [key: string]: string | string[] }) => {
      handleSetSearchParams({ page: '1', ...data });
    },
    [handleSetSearchParams],
  );

  return {
    output,
    isLoading,
    page: Number(page) || 1,
    search_string: search_string ?? '',
    statuses,
    payment_statuses: payment_statuses ?? '',
    handleSetSearchParams,
    updateFilters,
    totalRecords: data?.total ?? 0,
  };
};
