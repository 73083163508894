import {
  GeneralDto,
  ProfilePayload,
  ClubMembershipValues,
  ClubMembershipPayload,
  ClubMembershipDto,
  getFullName,
  getCurrencyString,
  AddressDto,
  dateInputOutputFormat,
} from 'shared';
import { NONE_VALUE } from '../constants';
import { omit } from 'rambda';

export const generalInitialValues: ProfilePayload = {
  first_name: '',
  last_name: '',
  customer_source_id: null,
  date_of_birth: '',
  email: '',
  phone_number: '',
  preferred_primary_location_id: null,
  secondary_location_id: null,
  email_opt_in: true,
  sms_opt_in: true,
  pos_notes: '',
  system_notes: '',
};

export const addressInitialValues: AddressDto = {
  address_name: '',
  first_name: '',
  last_name: '',
  date_of_birth: null,
  email: null,
  phone_number: null,
  country: 'USA',
  state: '',
  city: '',
  business: '',
  street_address_one: '',
  street_address_two: '',
  zip_code: null,
  is_same_as_shipping_address: false,
  is_default: false,
};

export const clubMemberInitialValues: ClubMembershipValues = {
  tier: {
    id: '',
    wine_type: null,
    bottle_quantity: null,
  },
  add_on: {
    id: '',
    wine_type: null,
    bottle_quantity: null,
  },
  account_group: null,
  isPickup: true,
  hasShipment: false,
  pickup: {
    location_id: '',
  },
  shipment: {
    address_id: '',
  },
  is_cycle_on_hold: false,
  reason: null,
  customer_source_id: null,
};

export const normalizeProfile = (data: GeneralDto): ProfilePayload => {
  return {
    ...data,
    lifetime_value: data?.lifetime_value
      ? getCurrencyString(data?.lifetime_value)
      : '',

    date_of_birth: dateInputOutputFormat(data.date_of_birth as string, 'input'),
    preferred_primary_location_id: data.preferred_primary_location?.id ?? null,
    secondary_location_id: data.secondary_location?.id ?? null,
  };
};

const getShipmentData = (data: ClubMembershipValues['shipment']) => {
  if (!data?.address_id) {
    return {
      address_id: null,
    };
  }
  return data;
};

export const getClubMemberPayload = (
  data: ClubMembershipValues,
): ClubMembershipPayload => {
  const withoutAccountGroup = omit(['account_group'], data);
  const { isPickup, add_on, shipment, hasShipment, tier, ...rest } =
    withoutAccountGroup;

  return {
    ...rest,
    tier: tier.id === NONE_VALUE ? null : { ...tier },
    shipment: isPickup || !hasShipment ? null : getShipmentData(shipment),
    add_on: add_on?.id ? add_on : null,
    pickup:
      isPickup && hasShipment
        ? {
            location_id: rest.pickup?.location_id ?? '',
          }
        : null,
  };
};

export const getClubMemberValues = (
  data: ClubMembershipDto,
): ClubMembershipValues => {
  const { pickup, shipment, tier, add_on, ...rest } = data;
  const { address } = shipment ?? {};

  return {
    ...rest,
    tier: tier
      ? {
          ...tier,
          id: tier.club_tier.id,
        }
      : {
          id: 'None',
          wine_type: null,
          bottle_quantity: null,
        },
    add_on: add_on
      ? {
          ...add_on,
          id: add_on.club_tier.id,
        }
      : clubMemberInitialValues.add_on,
    isPickup: !!pickup,
    pickup: {
      location_id: pickup?.location.id ?? '',
    },
    shipment: {
      address_id: shipment?.address.id ?? '',
      address: address && omit(['id'], address),
    },
  };
};

const getTierChangeText = ({
  currentTierName,
  newTierName,
}: {
  currentTierName: string;
  newTierName?: string;
}) => {
  if (currentTierName === newTierName) return '';
  if (newTierName) return `Change Tier: ${currentTierName} to ${newTierName}`;

  return 'Cancel Membership';
};

const getAddOnText = (addOnName?: string) => {
  if (!addOnName) return 'Add-On: Cancel';

  return 'Add-On: Silver Label';
};

export const getStatusClubMember = ({
  clubMembership,
}: {
  clubMembership?: ClubMembershipDto;
}) => {
  const { club_membership_request, tier } = clubMembership ?? {};
  const { tier: newTier } = club_membership_request ?? {};

  if (
    club_membership_request &&
    tier?.club_tier.name !== newTier?.club_tier.name
  )
    return getTierChangeText({
      currentTierName: tier?.club_tier.name ?? NONE_VALUE,
      newTierName: newTier?.club_tier.name,
    });

  if (club_membership_request)
    return getAddOnText(club_membership_request?.add_on?.club_tier.name);
};

export const normalizeChangeTierData = ({
  clubMembership,
}: {
  clubMembership: ClubMembershipDto;
}) => {
  const { club_membership_request, tier } = clubMembership ?? {};
  const {
    add_on,
    reason,
    customer_source,
    tier: requestTier,
  } = club_membership_request ?? {};
  const currentTierName = tier?.club_tier.name ?? NONE_VALUE;
  const newTierName = requestTier?.club_tier.name;

  const changeTier = getTierChangeText({ currentTierName, newTierName });
  const addOn = getAddOnText(add_on?.club_tier.name);

  const fullName = getFullName({
    first_name: customer_source?.first_name,
    last_name: customer_source?.last_name,
  });

  return { changeTier, reason, fullName, addOn };
};
