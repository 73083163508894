import { useNavigate } from 'react-router-dom';
import { DataTable } from 'mantine-datatable';
import { Button } from '@mantine/core';

import {
  ContentLayout,
  CreateButton,
  SearchInput,
  SearchInputSingleLayout,
} from 'components';
import { PROPS_DATA_TABLE, getCreatePath, getEditPath } from 'shared';
import { useCorporateOrders } from '../../hooks';
import { listColumns } from '../../constants';
import TemplateOrder from 'assets/template/corporateOrderTemplate.csv';

export const List = ({ rootPath }: { rootPath: string }) => {
  const {
    output,
    page,
    totalRecords,
    search_string,
    isLoading,
    updateFilters,
    handleSetSearchParams,
  } = useCorporateOrders();
  const navigate = useNavigate();

  const title = 'Corporate Orders';

  return (
    <ContentLayout
      title={title}
      processing={isLoading}
      rightAction={
        <CreateButton to={getCreatePath(rootPath)}>
          + New Corporate Order
        </CreateButton>
      }
      leftAction={
        <Button
          component="a"
          href={TemplateOrder}
          download="corporate-order-template"
        >
          Get Order Template File
        </Button>
      }
    >
      <SearchInputSingleLayout>
        <SearchInput
          label="Search"
          placeholder="Name or card number"
          value={search_string}
          handleSubmit={(value: string) =>
            updateFilters({ search_string: value })
          }
        />
      </SearchInputSingleLayout>

      <DataTable
        {...PROPS_DATA_TABLE}
        page={page}
        records={output}
        totalRecords={totalRecords}
        onPageChange={(p) => handleSetSearchParams({ page: p.toString() })}
        onRowClick={({ id }) => navigate(getEditPath(rootPath, id))}
        columns={listColumns}
      />
    </ContentLayout>
  );
};
