import { useCallback, useMemo } from 'react';
import { isEmpty, reject } from 'rambda';
import { useApiQuery, useQueryParams } from 'hooks';
import { DEFAULT_PAGE_SIZE } from 'shared';
import { normalizeCorporateOrdersList } from '../helper';
import { CorporateOrder } from 'App/api';

type RequestFilter = { search_string: string };

export const useCorporateOrders = () => {
  const { searchParams, handleSetSearchParams } = useQueryParams({
    page: '1',
    search_string: '',
  });

  const page = Number(searchParams.get('page') ?? 1);
  const search_string = searchParams.get('search_string') ?? '';

  const params = useMemo(() => {
    return {
      page,
      page_size: DEFAULT_PAGE_SIZE,
      search_string,
    };
  }, [page, search_string]);

  const key = JSON.stringify(params);

  const { data, isLoading } = useApiQuery(['getCorporateOrders', key], () =>
    CorporateOrder.getCollection(
      reject(isEmpty, params as Record<string, unknown>),
    ),
  );

  const updateFilters = useCallback(
    (data: RequestFilter) => {
      handleSetSearchParams({ page: '1', ...data });
    },
    [handleSetSearchParams],
  );

  const output = useMemo(
    () => data && normalizeCorporateOrdersList(data?.items),
    [data],
  );

  return {
    page,
    output,
    search_string,
    isLoading,
    updateFilters,
    handleSetSearchParams,
    totalRecords: data?.total ?? 0,
  };
};
