import { useCallback, useMemo, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { AxiosError } from 'axios';
import {
  useApiMutation,
  useCustomers,
  useToast,
  useUserAddresses,
  useUserCards,
} from 'hooks';
import {
  CardDto,
  ClubMemberShip,
  CustomerDto,
  CycleOrderPayload,
  formatDate,
  getClubMembership,
  getEditPath,
  getSelectOptions,
} from 'shared';
import { ShipmentSalesOrdersService } from 'App/api';
import { getInitialValue } from '../helpers';

export const useCreateOrder = ({ rootPath }: { rootPath: string }) => {
  const [clubMemberShip, setClubMemberShip] = useState<ClubMemberShip | null>(
    null,
  );
  const [cards, setCards] = useState<CardDto[] | null>(null);

  const { state }: { state?: CustomerDto } = useLocation();

  const toast = useToast();
  const navigate = useNavigate();

  const { mutate: createOrder, isLoading: isCreatingOrder } = useApiMutation(
    ['create-order'],
    ShipmentSalesOrdersService.create,
    {
      onSuccess: (data) => {
        toast.success('Order has been created!');
        navigate(getEditPath(rootPath, data.id));
      },
    },
  );

  const {
    userOptions,
    hasMore: hasMoreUsers,
    output: usersOutput,
    isLoading: isLoadingUsers,
    setPageSelectAsync: setPageSelectUsers,
    handleSetSearchString: handleSetSearchStringUsers,
  } = useCustomers({
    isSelectAsync: true,
  });

  const { getCardsAsync } = useUserCards({});
  const { getAddressesAsync } = useUserAddresses({});

  const handleGetCards = useCallback(
    async (id?: string) => {
      const cardsData = await getCardsAsync({ id });

      setCards(cardsData.items);

      return cardsData;
    },
    [getCardsAsync],
  );

  const initialValues = useMemo(() => {
    setClubMemberShip(
      getClubMembership({
        mainTier: state?.club_membership.tier,
        addOnTier: state?.club_membership.add_on,
      }),
    );

    if (state?.id) {
      handleGetCards(state.id);
    }

    return getInitialValue({ state });
  }, [handleGetCards, state]);

  const cardOptions = useMemo(
    () =>
      cards &&
      getSelectOptions(
        cards?.map(({ id, card_name }) => ({
          id,
          name: card_name,
        })),
      ),
    [cards],
  );

  const getCustomerDataById = async (customerId: string) => {
    try {
      const { wine_club_tier, wine_club_add_on, id } =
        usersOutput.find(({ id }) => id === customerId) ?? {};

      const cardsData = await handleGetCards(id);
      const addressesData = await getAddressesAsync({ id });

      const defaultCard = cardsData.items.find(({ is_default }) => is_default);
      const defaultAddresses = addressesData.items.find(
        ({ is_default }) => is_default,
      );

      setClubMemberShip(
        getClubMembership({
          mainTier: wine_club_tier,
          addOnTier: wine_club_add_on,
        }),
      );

      return {
        defaultCardId: defaultCard?.id,
        defaultAddressesId: defaultAddresses?.id,
      };
    } catch (error) {
      const { message } = error as AxiosError;

      toast.error(message);
    }
  };

  const handleCreateOrder = (data: CycleOrderPayload) => {
    const payload = {
      ...data,
      shipping_date: formatDate(data.shipping_date),
      pick_up_deadline: formatDate(data.pick_up_deadline),
    };

    createOrder(payload);
  };

  return {
    initialValues,
    isLoadingUsers,
    isCreatingOrder,
    clubMemberShip,
    cardOptions,
    handleCreateOrder,
    getCustomerDataById,
    asyncSelectUsers: {
      hasMore: hasMoreUsers,
      options: userOptions,
      setPageSelectAsync: setPageSelectUsers,
      setSearchStringSelectAsync: handleSetSearchStringUsers,
    },
  };
};
