import { generateRoutePath, RouteName, useGotoRoute } from 'App/routes';
import { Create, Edit, List } from './components';
import { NestedRouteComponent } from 'components';
import { usePermissions } from 'hooks';
import { Permissions } from 'shared';

export const SalesOrders = () => {
  const { checkAccess } = usePermissions();
  const rootPath = generateRoutePath({
    name: RouteName.SalesOrders,
  });
  const onExit = useGotoRoute(RouteName.SalesOrders);

  const canEdit = checkAccess(
    Permissions.admin_panel_shipment_sales_order_edit,
  );
  const canCreate = checkAccess(
    Permissions.admin_panel_shipment_sales_order_create,
  );

  const ListComponent = <List rootPath={rootPath} />;
  const EditComponent = <Edit onExit={onExit} />;
  const CreateComponent = <Create rootPath={rootPath} />;

  return (
    <NestedRouteComponent
      showEdit={canEdit}
      showCreate={canCreate}
      ListComponent={ListComponent}
      Edit={EditComponent}
      Create={CreateComponent}
      rootPath={rootPath}
    />
  );
};
