import { useNavigate } from 'react-router-dom';
import { Grid, Box, Flex, Switch } from '@mantine/core';
import { DataTable } from 'mantine-datatable';
import {
  ContentLayout,
  CreateButton,
  HintSection,
  SearchInput,
} from 'components';
import {
  PROPS_DATA_TABLE,
  SWITCH_GRID_TOP_OFFSET,
  ShipmentCycleTabValue,
  accountGroupLabels,
  getCreatePath,
  getTabEditPath,
} from 'shared';
import { customerHintOptions } from '../../constants';
import { useCustomers } from 'hooks';

const markStyle = {
  width: '2px',
  height: '40px',
  margin: '-8px 0',
};

export const List = ({
  canCreate,
  rootPath,
}: {
  canCreate: boolean;
  rootPath: string;
}): JSX.Element => {
  const navigate = useNavigate();
  const {
    page,
    search_string,
    output,
    totalRecords,
    isLoading,
    isCycleOnHold,
    handleSetSearchParams,
    updateFilters,
    setIsCycleOnHold,
  } = useCustomers({});
  return (
    <ContentLayout
      title="Customers"
      rightAction={
        canCreate && (
          <CreateButton
            data-testid="new-customer-btn"
            to={getCreatePath(rootPath)}
          >
            + New Customer
          </CreateButton>
        )
      }
      processing={isLoading}
    >
      <Grid mb="md" justify="space-between">
        <Grid.Col span={5} pt={SWITCH_GRID_TOP_OFFSET}>
          <Switch
            label="Excluded from Shipment Cycle"
            checked={isCycleOnHold}
            onChange={(e) => {
              handleSetSearchParams({ page: '1' });
              setIsCycleOnHold(e.currentTarget.checked);
            }}
          />
        </Grid.Col>
        <Grid.Col span={5}>
          <SearchInput
            handleSubmit={(value: string) =>
              updateFilters({ search_string: value })
            }
            label="Search"
            value={search_string}
          />
        </Grid.Col>
      </Grid>
      <DataTable
        data-testid="customers-table"
        {...PROPS_DATA_TABLE}
        records={output}
        page={page}
        onPageChange={(p) => handleSetSearchParams({ page: p.toString() })}
        totalRecords={totalRecords}
        onRowClick={({ id }) =>
          navigate(getTabEditPath(rootPath, id, ShipmentCycleTabValue.profile))
        }
        columns={[
          {
            accessor: 'statusCustomer',
            title: '',
            render: ({ statusCustomer }) => (
              <Flex sx={{ width: '10px' }} direction="row" gap={1}>
                {statusCustomer.has_error && (
                  <Box
                    data-testid={`customer-item${
                      statusCustomer.has_error
                        ? '-has-missing-fields'
                        : '-has-all-fields-populated'
                    }`}
                    sx={(theme) => ({
                      ...markStyle,
                      backgroundColor: theme.colors.orange[4],
                    })}
                  />
                )}
                {statusCustomer.has_club_membership_request && (
                  <Box
                    data-testid={`customer-item${
                      statusCustomer.has_club_membership_request
                        ? '-has-club-membership'
                        : '-without-club-membership'
                    }`}
                    sx={(theme) => ({
                      ...markStyle,
                      backgroundColor: theme.colors.red[7],
                    })}
                  />
                )}
              </Flex>
            ),
            width: '2%',
          },
          { width: '10%', accessor: 'first_name', title: 'First Name' },
          { width: '10%', accessor: 'last_name', title: 'Last Name' },
          {
            width: '17%',
            accessor: 'account_group',
            render: ({ account_group }) => accountGroupLabels[account_group],
            title: 'Account Group',
          },
          {
            width: '7%',
            accessor: 'wine_club_tier',
            title: 'Club Tier',
            render: ({ wine_club_tier }) =>
              wine_club_tier?.club_tier.name ?? '',
          },
          { width: '7%', accessor: 'clubAddOn', title: 'Club Add-On' },
          { width: '7%', accessor: 'wine_club_status', title: 'Club Status' },
          { width: '150px', accessor: 'email', title: 'Email', ellipsis: true },
          { width: '20%', accessor: 'phone_number', title: 'Phone' },
        ]}
      />
      <HintSection options={customerHintOptions} />
    </ContentLayout>
  );
};
