import { Link } from 'react-router-dom';
import { ContentLayout, BackButton } from 'components';
import { CreateForm } from '../form';

type Props = {
  rootPath: string;
};

const title = 'New Corporate Order';

export const Create = ({ rootPath }: Props) => {
  return (
    <ContentLayout
      title={title}
      processing={false}
      leftAction={<BackButton component={Link} to={rootPath} />}
    >
      <CreateForm rootPath={rootPath} />
    </ContentLayout>
  );
};
