import { useMemo, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import {
  CustomerService,
  OrdersService,
  PaymentsService,
  ShipmentSalesOrdersService,
  SkuService,
} from 'App/api';
import {
  useApiMutation,
  useApiQuery,
  usePrivateDocument,
  useToast,
} from 'hooks';
import {
  SkuStatus,
  CycleOrderPayload,
  getOrderPayload,
  walletsOptions,
  normalizeOrder,
  getOrderValues,
  getClubMembership,
  normalizePayment,
  normalizeLinkOrders,
  SendReceiptPayload,
  SUCCESS_MESSAGE_GENERATION_RECEIPT,
  RefundItemRequest,
  RefundType,
  OrderDto,
  RecalculateRequest,
  PayRecalculateRequest,
  normalizeCorrections,
  getPayRecalculateData,
  getPayments,
  OrderPaymentStatus,
  OrderStatus,
  sortPaymentsForUi,
  PaymentStatus,
} from 'shared';

type Props = {
  id: string;
  queryKey: 'sales' | 'cycle';
  onExit: () => void;
};

export const useEditOrders = ({ id, queryKey, onExit }: Props) => {
  const ORDER_QUERY_KEY = `order-${queryKey}`;
  const SKIPPED_ORDER = `skipped-${ORDER_QUERY_KEY}`;
  const CANCEL_ORDER = `cancel-${ORDER_QUERY_KEY}`;
  const UPDATE_ORDER = `update-${ORDER_QUERY_KEY}`;
  const SKIP_ORDER = `skip-${ORDER_QUERY_KEY}`;
  const RESEND_ORDER = `resend-${ORDER_QUERY_KEY}`;
  const CONFIRM_AND_ORDER = `confirm-and-pay-${ORDER_QUERY_KEY}`;
  const PAYMENT = `payment-${ORDER_QUERY_KEY}`;
  const SEND_RECEIPT = `send-receipt-${ORDER_QUERY_KEY}`;
  const REGENERATE_RECEIPT = `regenerate-receipt-${ORDER_QUERY_KEY}`;

  const [generationPDFPaymentId, setGenerationPDFPaymentId] = useState<
    string | null
  >(null);
  const [recalculateOrder, setRecalculateOrder] = useState<OrderDto | null>(
    null,
  );

  const isSales = queryKey === 'sales';

  const addToast = useToast();

  const {
    getDocument,
    document: signature,
    isLoading: isLoadingSignature,
  } = usePrivateDocument();

  const {
    data: payment,
    isLoading: isLoadingPayments,
    refetch: refetchPayment,
  } = useApiQuery([PAYMENT, id], () => PaymentsService.getCollection(id), {
    onSuccess: (data) => {
      const { s3_receipt_path } =
        data.items.find((i) => i.id === generationPDFPaymentId) ?? {};

      if (s3_receipt_path) {
        setGenerationPDFPaymentId(null);
      }
    },
    refetchInterval: !!generationPDFPaymentId && 10000,
  });

  const {
    data: order,
    isLoading,
    isFetching,
  } = useApiQuery(
    [ORDER_QUERY_KEY, id],
    () => ShipmentSalesOrdersService.getOne({ id }),
    {
      onSuccess: (data) =>
        data.signature_url && getDocument(data.signature_url),
    },
  );

  const client = useQueryClient();
  const refetch = () => {
    client.invalidateQueries([ORDER_QUERY_KEY, id]);
  };

  const { data: skus, isLoading: isLoadingSkus } = useApiQuery(
    ['inventory-skus'],
    () =>
      SkuService.getCollection({
        status: SkuStatus.Enabled,
        has_inventory: true,
        page_size: 1000,
      }),
  );

  const { data, isLoading: isLoadingCards } = useApiQuery(
    ['cards', id],
    () => CustomerService.getCards({ id: order?.customer.id }),
    {
      enabled: !!order?.customer.id,
    },
  );

  const { data: skippedOrders } = useApiQuery(
    [SKIPPED_ORDER, order?.checkout?.customer_id],
    () =>
      ShipmentSalesOrdersService.getSkippedCustomerOrders({
        customer_id: order?.checkout?.customer_id,
      }),
    {
      enabled: !!order?.checkout?.customer_id,
    },
  );

  const { mutate: sendReceipt, isLoading: isSendingReceipt } = useApiMutation(
    [SEND_RECEIPT, id],
    OrdersService.sendReceipt,
    {
      onSuccess: () => addToast.success('The receipt has been sent!'),
    },
  );

  const { mutate: recalculatePaidOrder, isLoading: isRecalculation } =
    useApiMutation(
      ['recalculate-order', id],
      ShipmentSalesOrdersService.recalculatePaidOrder,
      { onSuccess: (data) => setRecalculateOrder(normalizeCorrections(data)) },
    );

  const { mutate: payRecalculateOrder, isLoading: isPayRecalculation } =
    useApiMutation(
      ['recalculate-order', id],
      ShipmentSalesOrdersService.payRecalculateOrder,
      {
        onSuccess: (data) => {
          setRecalculateOrder(data);
          addToast.success('Delivery method has been changed!');
        },
      },
    );

  const { mutateAsync: refund, isLoading: isRefunding } = useApiMutation(
    [SEND_RECEIPT, id],
    OrdersService.refund,
    {
      onSuccess: () => {
        refetch();
        refetchPayment();

        addToast.success('Refund has been completed!');
      },
    },
  );
  const { mutateAsync: regenerateReceipt } = useApiMutation(
    [REGENERATE_RECEIPT, id],
    PaymentsService.regenerateReceipt,
    {
      onSuccess: () => addToast.success(SUCCESS_MESSAGE_GENERATION_RECEIPT),
    },
  );

  const { mutate: updateOrder, isLoading: isUpdating } = useApiMutation(
    [UPDATE_ORDER, id],
    ShipmentSalesOrdersService.update,
    {
      onSuccess: () => {
        if (isSales) onExit();
        refetch();
        addToast.success('Order has been updated!');
      },
    },
  );

  const { mutate: updateOrderV2, isLoading: isUpdatingV2 } = useApiMutation(
    [UPDATE_ORDER, id],
    ShipmentSalesOrdersService.updateV2,
    {
      onSuccess: () => {
        if (isSales) onExit();
        refetch();
        addToast.success('Order has been updated!');
      },
    },
  );

  const { mutate: cancel, isLoading: isCanceling } = useApiMutation(
    [CANCEL_ORDER, id],
    ShipmentSalesOrdersService.cancel,
    {
      onSuccess: () => {
        refetch();
        refetchPayment();
      },
    },
  );

  const { mutate: skip, isLoading: isSkipping } = useApiMutation(
    [SKIP_ORDER, id],
    ShipmentSalesOrdersService.skip,
    {
      onSuccess: () => {
        onExit();
        refetch();
      },
    },
  );

  const { mutate: resend, isLoading: isResending } = useApiMutation(
    [RESEND_ORDER, id],
    ShipmentSalesOrdersService.resendToShipcompliant,
    {
      onSuccess: () => refetch(),
    },
  );
  const { mutate: confirmAndPay, isLoading: isPaying } = useApiMutation(
    [CONFIRM_AND_ORDER, id],
    ShipmentSalesOrdersService.confirmAndPay,
    {
      onSuccess: () => {
        addToast.success(
          'The payment has been added to the queue for processing!',
        );
        refetch();
      },
    },
  );

  const handleSendReceipt = (data: SendReceiptPayload) => {
    sendReceipt({ id, data });
  };

  const handleRegenerateReceipt = async (id: string) => {
    try {
      setGenerationPDFPaymentId(id);
      await regenerateReceipt({ id });
    } catch {
      setGenerationPDFPaymentId(null);
    }
  };

  const handlePayRecalculateOrder = (data: PayRecalculateRequest) => {
    payRecalculateOrder({ id, data });
  };

  const handleSubmit = (data: CycleOrderPayload) => {
    if (
      recalculateOrder?.calculated_correction ||
      (order?.payment_status === OrderPaymentStatus.ERROR &&
        order.status === OrderStatus.PENDING_DELIVERY_METHOD_CHANGE)
    ) {
      return handlePayRecalculateOrder(
        getPayRecalculateData(data) as PayRecalculateRequest,
      );
    }
    return isSales
      ? updateOrderV2({ id, data: getOrderPayload(data) })
      : updateOrder({ id, data: getOrderPayload(data) });
  };

  const handleRecalculatePaidOrder = (data: RecalculateRequest) => {
    recalculatePaidOrder({ id, data });
  };

  const handleCancel = (id: string) => cancel({ id });

  const handleRefund = ({
    type,
    paymentData,
  }: {
    type: RefundType;
    paymentData: {
      payment_id: string;
      items: RefundItemRequest[];
    };
  }) =>
    refund({
      id,
      data: {
        refund_type: type,
        payment: { ...paymentData },
      },
    });

  const cards = useMemo(() => walletsOptions(data?.items), [data]);

  const normalized = useMemo(() => {
    if (recalculateOrder) {
      return normalizeOrder(recalculateOrder);
    } else {
      return order && normalizeOrder(order);
    }
  }, [order, recalculateOrder]);

  const initialValues = useMemo(() => {
    if (recalculateOrder) {
      return getOrderValues(recalculateOrder);
    } else {
      return order && getOrderValues(order);
    }
  }, [order, recalculateOrder]);

  const isCancelWithSkip = useMemo(
    () => skippedOrders && !!skippedOrders?.items.length,
    [skippedOrders],
  );

  const clubMemberShip = useMemo(
    () =>
      getClubMembership({
        mainTier: order?.customer.main_tier,
        addOnTier: order?.customer.add_on_tier,
        shipmentCycle: order?.shipment_cycle,
      }),
    [order],
  );

  const normalizePayments = useMemo(
    () => normalizePayment(payment?.items),
    [payment],
  );

  const linkOrders = useMemo(() => {
    const successfulPayments =
      payment?.items?.filter(({ status }) => status !== PaymentStatus.failed) ??
      [];

    return normalizeLinkOrders(successfulPayments[0]?.sales_orders, id);
  }, [id, payment?.items]);

  return {
    order: normalized,
    skus: skus?.items,
    cards: getPayments({ card: order?.card, cards: cards }),
    signature,
    payment: sortPaymentsForUi(normalizePayments),
    linkOrders,
    initialValues,
    clubMemberShip,
    isSkipping,
    isCanceling,
    isResending,
    isSendingReceipt,
    generationPDFPaymentId,
    isCancelWithSkip,
    isLoading:
      isLoading ||
      isLoadingSkus ||
      isLoadingCards ||
      isLoadingSignature ||
      isLoadingPayments ||
      isFetching ||
      isRecalculation ||
      isPayRecalculation,
    isUpdating: isUpdatingV2 || isUpdating,
    isPaying,
    isRecalculation,
    isRefunding: isRefunding || isLoadingPayments || isLoading,
    skip,
    resend,
    handleRefund,
    handleRecalculatePaidOrder,
    handleRegenerateReceipt,
    handleSendReceipt,
    handleCancel,
    handleSubmit,
    confirmAndPay,
    setRecalculateOrder,
  };
};
