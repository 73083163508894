import { SingleValue } from 'react-select';
import { Grid, Select, Text } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import {
  ActionButtons,
  DeliveryData,
  GeneralInfo,
  InventoryDetails,
  PromocodeSelect,
  SelectAsync,
} from 'components';
import {
  AsyncSelect,
  ClubMemberShip,
  CycleOrderPayload,
  getOrderSchema,
  SelectOption,
} from 'shared';

type Props = {
  isLoadingUsers: boolean;
  asyncSelectUsers: AsyncSelect;
  cardOptions?: SelectOption[] | null;
  clubMemberShip: ClubMemberShip | null;
  initialValues?: CycleOrderPayload;
  handleCreateOrder: (data: CycleOrderPayload) => void;
  getCustomerDataById: (customerId: string) => Promise<
    | {
        defaultCardId?: string;
        defaultAddressesId?: string;
      }
    | undefined
  >;
};

export const CreateOrderForm = ({
  initialValues,
  isLoadingUsers,
  asyncSelectUsers,
  cardOptions,
  clubMemberShip,
  handleCreateOrder,
  getCustomerDataById,
}: Props) => {
  const form = useForm({
    initialValues,
    validate: yupResolver(getOrderSchema()),
    validateInputOnBlur: true,
  });

  const disabledSave = !form.isValid() || !form.isDirty();

  return (
    <form onSubmit={form.onSubmit(handleCreateOrder)}>
      <GeneralInfo<CycleOrderPayload>
        form={form}
        clubMemberShip={clubMemberShip}
        selectCustomer={
          <SelectAsync
            required
            isSearchable
            searchByString={({ search_string }) =>
              asyncSelectUsers.setSearchStringSelectAsync(search_string)
            }
            label="Customer Name"
            setPage={asyncSelectUsers.setPageSelectAsync}
            hasMore={asyncSelectUsers.hasMore}
            isLoading={isLoadingUsers}
            options={asyncSelectUsers.options}
            defaultValue={form.values.defaultCustomer}
            {...form.getInputProps('customer_id')}
            onChange={async (item: SingleValue<SelectOption>) => {
              if (item?.value) {
                const { defaultCardId, defaultAddressesId } =
                  (await getCustomerDataById(item.value)) ?? {};

                form.setFieldValue('customer_id', item.value);

                form.setFieldValue('customer_card_id', defaultCardId ?? '');
                form.setFieldValue(
                  'customer_shipping_address_id',
                  defaultAddressesId ?? '',
                );
              }
            }}
          />
        }
      />
      <InventoryDetails form={form} />

      <PromocodeSelect form={form} />
      <DeliveryData form={form} customerId={form.values?.customer_id} />
      <Grid>
        <Grid.Col span={4} my={25}>
          <Text weight={600} mb="sm">
            Payment Method
          </Text>
          <Select
            required
            label="Card"
            data={cardOptions ?? []}
            {...form.getInputProps('customer_card_id')}
          />
        </Grid.Col>
      </Grid>

      <ActionButtons
        marginTop={20}
        disabled={disabledSave}
        type="submit"
        label="Save"
        aria-label="save"
      />
    </form>
  );
};
