import { CustomerService } from 'App/api';
import { useApiQuery } from './useApiQuery';
import { useQueryClient } from '@tanstack/react-query';

const key = 'addresses';

export const useUserAddresses = ({ id }: { id?: string }) => {
  const { data, isFetching, isLoading, refetch } = useApiQuery(
    [key, id],
    () => CustomerService.getAddresses({ id }),
    {
      enabled: !!id,
    },
  );

  const client = useQueryClient();

  const getAddressesAsync = ({ id }: { id?: string }) =>
    client.ensureQueryData({
      queryKey: [key, id],
      cacheTime: 0,
      queryFn: () => CustomerService.getAddresses({ id }),
    });

  return { data, isFetching, isLoading, refetch, getAddressesAsync };
};
