import { Fragment } from 'react';
import { Box, Button, Container } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import {
  BottomButtons,
  ChangeHistory,
  ComplianceHistory,
  CorrectionPayment,
  DeliveryData,
  DeliveryInformation,
  GeneralInfo,
  InventoryDetails,
  LinkOrders,
  OrderAdditionalInfo,
  PaymentActions,
  PaymentDetails,
  PaymentInformation,
  ShipcompliantStatus,
} from 'components';
import {
  OrderDto,
  InventoryType,
  CycleOrderPayload,
  WalletSelectOption,
  PrivateDocumentDto,
  SalesOrderComplianceStatus,
  getOrderSchema,
  OrderPaymentStatus,
  Payments,
  LinkOrderOptions,
  SendReceiptPayload,
  RefundItemRequest,
  RefundType,
  RecalculateRequest,
  getPaymentMethod,
  TransactionType,
  PaymentMethod,
  ClubMemberShip,
  OrderStatus,
} from 'shared';
import { RefundOrderView } from './RefundOrderView';
import { Mode } from '../../constants';

type PropType = {
  mode: Mode;
  clubMemberShip: ClubMemberShip;
  goBack: () => void;
  goChangeHistory: () => void;
  initialValues: CycleOrderPayload;
  cards: WalletSelectOption[];
  order: OrderDto;
  signature: PrivateDocumentDto | null;
  payment: Payments[];
  linkOrders?: LinkOrderOptions;
  loading: boolean;
  isPaying: boolean;
  isRefunding: boolean;
  isCycleOrder?: boolean;
  isResending: boolean;
  isSkipping: boolean;
  isCancelWithSkip?: boolean;
  generationPDFPaymentId: string | null;
  resend: ({ id }: { id: string }) => void;
  handleSubmit: (values: CycleOrderPayload) => void;
  skip: ({ id }: { id?: string }) => void;
  cancel: (id: string) => void;
  confirmAndPay: ({ id }: { id?: string }) => void;
  handleRefund: ({
    type,
    paymentData,
  }: {
    type: RefundType;
    paymentData: {
      payment_id: string;
      items: RefundItemRequest[];
    };
  }) => void;
  setRecalculateOrder: React.Dispatch<React.SetStateAction<OrderDto | null>>;
  handleSendReceipt: (data: SendReceiptPayload) => void;
  handleRegenerateReceipt: (id: string) => void;
  handleRecalculatePaidOrder: (data: RecalculateRequest) => void;
};

export const Form = ({
  mode,
  clubMemberShip,
  goBack,
  goChangeHistory,
  initialValues,
  order,
  linkOrders,
  cards,
  signature,
  payment,
  loading,
  isPaying,
  isRefunding,
  isCycleOrder,
  isSkipping,
  isResending,
  isCancelWithSkip,
  generationPDFPaymentId,
  skip,
  cancel,
  resend,
  handleSubmit,
  handleRefund,
  confirmAndPay,
  setRecalculateOrder,
  handleSendReceipt,
  handleRegenerateReceipt,
  handleRecalculatePaidOrder,
}: PropType) => {
  const form = useForm({
    initialValues,
    validate: yupResolver(getOrderSchema(true)),
    validateInputOnBlur: true,
  });

  const { shipping_address, number: orderNumber } = order;

  const alcoholItemsCount = form.values.items.reduce(
    (accumulator, currentValue) =>
      accumulator +
      (currentValue.sku_inventory_type === InventoryType.ALCOHOL
        ? currentValue.quantity
        : 0),
    0,
  );

  const isCash = payment[0]?.method === PaymentMethod.cash;

  const isInShipping =
    initialValues.status === OrderStatus.SHIPPING &&
    initialValues.delivery_method === 'Shipping';

  const isErrorComplianceStatus =
    initialValues.compliance_status === SalesOrderComplianceStatus.ERROR;

  const isSkipped = OrderStatus.SKIPPED === initialValues.status;
  const isRefunded = [
    OrderPaymentStatus.REFUND,
    OrderPaymentStatus.PARTIALLY_REFUND,
  ].includes(order.payment_status);

  const isPaid = [
    OrderPaymentStatus.PAID,
    OrderPaymentStatus.PARTIALLY_REFUND,
  ].includes(order.payment_status);

  if (Mode.HISTORY === mode)
    return <ComplianceHistory goBack={goBack} orderId={order.id} />;

  if (Mode.CHANGE_HISTORY === mode)
    return <ChangeHistory goBack={goBack} orderId={order.id} />;

  return (
    <Box>
      {isInShipping && (
        <ShipcompliantStatus
          action={
            isErrorComplianceStatus ? (
              <Button
                variant="outline"
                loading={isResending}
                onClick={() => resend({ id: order.id })}
              >
                Resend to Shipcompliant
              </Button>
            ) : null
          }
          complianceStatus={initialValues.compliance_status}
        />
      )}
      <GeneralInfo<CycleOrderPayload>
        form={form}
        clubMemberShip={clubMemberShip}
        isRefunded={isRefunded}
      />
      {isRefunded ? (
        <>
          <RefundOrderView
            isRefunding={isRefunding}
            paymentItems={payment}
            linkOrders={linkOrders}
            orderStatus={order.payment_status}
            corrections={order.corrections}
            orderNumber={order.number.toString()}
            generationPDFPaymentId={generationPDFPaymentId}
            handleSendReceipt={handleSendReceipt}
            handleRegenerateReceipt={handleRegenerateReceipt}
            handleRefund={handleRefund}
            productItems={
              form.values.items as {
                id: string;
                quantity: number;
                payment_status: OrderPaymentStatus;
              }[]
            }
          />
          <DeliveryInformation order={order} />
        </>
      ) : (
        <>
          <InventoryDetails
            form={form}
            isBlocked={isInShipping}
            corrections={order.corrections}
          />
          <LinkOrders linkOrders={linkOrders} />
          {isPaid ? (
            payment.map(
              ({
                id: paymentId,
                key,
                transactions,
                method,
                card_brand,
                card_last4,
                payer,
                payer_name,
                change,
                s3_receipt_path,
                signature_url,
                to_refund,
                status,
              }) => {
                const paymentMethod = getPaymentMethod({
                  method,
                  card_brand,
                  card_last4,
                });

                return (
                  <Fragment key={key}>
                    {transactions.map(
                      ({
                        id,
                        succeeded_at,
                        created_at,
                        status: transactionStatus,
                        amount,
                        type,
                        is_correction,
                        detalization,
                      }) => {
                        const isTransactionRefunded =
                          type === TransactionType.REFUND;

                        const { small_charge_fee } = detalization;

                        return (
                          <PaymentInformation
                            key={id}
                            payer={payer}
                            status={transactionStatus}
                            paymentMethod={paymentMethod}
                            method={method}
                            createAt={created_at}
                            succeededAt={succeeded_at}
                            change={change}
                            amount={amount}
                            smallChargeFee={small_charge_fee}
                            isRefunded={isTransactionRefunded}
                          >
                            <PaymentActions
                              isCycle
                              paymentId={paymentId}
                              paymentStatus={status}
                              method={method}
                              payer={payer}
                              payerName={payer_name}
                              s3ReceiptPath={s3_receipt_path}
                              signatureUrl={signature_url}
                              toRefund={to_refund}
                              transactionId={id}
                              isCorrection={is_correction}
                              isRefunding={isRefunding}
                              isRefunded={isTransactionRefunded}
                              paymentMethod={paymentMethod}
                              generationPDFIds={[generationPDFPaymentId ?? '']}
                              orderNumber={order.number.toString()}
                              handleSendReceipt={handleSendReceipt}
                              handleRefund={handleRefund}
                              handleRegenerateReceipt={handleRegenerateReceipt}
                              productItems={
                                form.values.items as {
                                  id: string;
                                  quantity: number;
                                  payment_status: OrderPaymentStatus;
                                }[]
                              }
                            />
                          </PaymentInformation>
                        );
                      },
                    )}
                  </Fragment>
                );
              },
            )
          ) : (
            <PaymentDetails
              isCycle
              isBlocked={isInShipping}
              form={form}
              cards={cards}
              status={order.payment_status}
            />
          )}

          {order?.calculated_correction && (
            <CorrectionPayment
              form={form}
              isCash={isCash}
              paymentCard={cards}
              totalDueCorrection={
                order.calculated_correction.total_due_correction
              }
            />
          )}

          <DeliveryData
            form={form}
            customerId={order.customer.id}
            setRecalculateOrder={setRecalculateOrder}
            handleRecalculatePaidOrder={handleRecalculatePaidOrder}
            defaultAddress={shipping_address?.address_line_1}
            shippingCost={order.shipping_cost}
          />
        </>
      )}

      {signature && <OrderAdditionalInfo signature={signature} />}
      <BottomButtons
        form={form}
        orderPaymentStatus={order.payment_status}
        isCancelWithSkip={isCancelWithSkip ?? isSkipping}
        orderNumber={orderNumber}
        loading={loading}
        isPaying={isPaying}
        isCycleOrder={isCycleOrder}
        isSkipped={isSkipped}
        isInShipping={isInShipping}
        isCorrection={!!order?.calculated_correction}
        isDisabled={alcoholItemsCount < clubMemberShip.bottleQty}
        handleSubmit={() => {
          handleSubmit({
            ...form.values,
            shipment_cycle_id: order.shipment_cycle.id,
          });
        }}
        onCancel={() => {
          if (form.values.is_skipped) {
            return skip({ id: order.id });
          }
          return cancel(order.id);
        }}
        confirmAndPay={() => confirmAndPay({ id: order.id })}
      />
      <Container size="xs" px={0}>
        <Button fullWidth onClick={goChangeHistory}>
          See change history
        </Button>
      </Container>
    </Box>
  );
};
