import { OrderStatus } from 'shared';

type Props = {
  status?: OrderStatus;
  editDraftOrder: JSX.Element;
  editCreatedOrder: JSX.Element;
};

export const StatusBasedForm = ({
  status,
  editDraftOrder,
  editCreatedOrder,
}: Props) => {
  const isDraft = status === OrderStatus.DRAFT;

  const isOrderCreationProgress =
    status &&
    [
      OrderStatus.SUBORDERS_CREATION_IS_SCHEDULED,
      OrderStatus.SUBORDERS_CREATION_IN_PROGRESS,
      OrderStatus.SUBORDERS_CREATION_ERROR,
    ].includes(status);

  if (isDraft || isOrderCreationProgress) return editDraftOrder;

  return editCreatedOrder;
};
