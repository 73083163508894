import { useCallback, useMemo } from 'react';
import { isEmpty, reject } from 'rambda';

import { PosSalesOrdersService } from 'App/api';
import { useApiQuery, useQueryParams } from 'hooks';
import { DEFAULT_PAGE_SIZE, OrderStatus, OrderPaymentStatus } from 'shared';
import { normalizePosSalesOrdersList } from '../helpers';

type RequestFilter = { search_string: string };

export const usePosOrders = () => {
  const { searchParams, handleSetSearchParams } = useQueryParams({
    page: '1',
    search_string: '',
  });

  const page = Number(searchParams.get('page') ?? 1);
  const search_string = searchParams.get('search_string') ?? '';

  const params = useMemo(() => {
    return {
      page,
      search_string,
      statuses: [OrderStatus.CLOSED, OrderStatus.CANCEL],
      payment_statuses: [
        OrderPaymentStatus.PAID,
        OrderPaymentStatus.REFUND,
        OrderPaymentStatus.PARTIALLY_REFUND,
      ],
      page_size: DEFAULT_PAGE_SIZE,
    };
  }, [page, search_string]);

  const key = JSON.stringify(params);

  const { data, isLoading } = useApiQuery(['getPosSalesOrders', key], () =>
    PosSalesOrdersService.getCollection(
      reject(isEmpty, params as Record<string, unknown>),
    ),
  );

  const output = useMemo(
    () => normalizePosSalesOrdersList(data?.items),
    [data],
  );

  const updateFilters = useCallback(
    (data: RequestFilter) => {
      handleSetSearchParams({ page: '1', ...data });
    },
    [handleSetSearchParams],
  );

  return {
    page,
    output,
    search_string,
    isLoading: isLoading,
    handleSetSearchParams,
    updateFilters,
    totalRecords: data?.total ?? 0,
  };
};
