import {
  Detalization,
  ItemTransaction,
  Payments,
  PaymentStatus,
  TransactionType,
} from 'shared/types';
import { getDateSOutput } from 'shared/utils';
import { getCurrencyString } from '../common';

export const normalizePayment = (data?: Payments[]) => {
  if (!data) return [];

  return data.map(({ transactions, ...rest }) => {
    return {
      ...rest,
      transactions: transactions.map(
        ({ succeeded_at, created_at, ...rest }) => ({
          ...rest,
          succeeded_at: getDateSOutput(succeeded_at),
          created_at: getDateSOutput(created_at),
        }),
      ),
    };
  });
};

const getCorrectionsRefundsErrors = (payments: Payments[]) => {
  const corrections = [] as Payments[];
  const refunds = [] as Payments[];
  const errors = [] as Payments[];

  payments.forEach(({ transactions, id: paymentId, status }, indexPayment) => {
    transactions.forEach(({ is_correction, type }) => {
      const isFailed = status === PaymentStatus.failed;
      if (isFailed) {
        errors.push({
          ...payments[indexPayment],
          key: `${payments[indexPayment].id}-error`,
          transactions: payments[indexPayment].transactions,
        });
      }
      if (
        is_correction &&
        !isFailed &&
        corrections.every(({ id }) => id !== paymentId)
      ) {
        corrections.push({
          ...payments[indexPayment],
          key: `${payments[indexPayment].id}-correction`,
          transactions: payments[indexPayment].transactions.filter(
            ({ is_correction }) => is_correction,
          ),
        });
      }
      if (
        !is_correction &&
        type === TransactionType.REFUND &&
        refunds.every(({ id }) => id !== paymentId)
      ) {
        refunds.push({
          ...payments[indexPayment],
          key: `${payments[indexPayment].id}-refund`,
          transactions: payments[indexPayment].transactions.filter(
            ({ type, is_correction }) =>
              !is_correction && type === TransactionType.REFUND,
          ),
        });
      }
    });
  });

  return { corrections, refunds, errors };
};

//sort for cycle and sales order
export const sortPaymentsForUi = (payments: Payments[]): Payments[] => {
  if (!payments.length) return [];

  const paymentWithoutFailed = payments.filter(
    ({ status }) => status !== PaymentStatus.failed,
  );

  const firstCharge = {
    ...paymentWithoutFailed[0],
    key: paymentWithoutFailed[0]?.id,
    transactions: [paymentWithoutFailed[0]?.transactions[0]],
  };
  const { corrections, refunds, errors } =
    getCorrectionsRefundsErrors(payments) ?? {};

  return [firstCharge, ...errors, ...corrections, ...refunds];
};

export const normalizePaymentItemsTable = (data: ItemTransaction) => {
  const {
    id,
    quantity,
    product_name,
    sku_price,
    sub_total,
    modifiers,
    sku_discounted_price,
    quantity_of_split_parts_per_unit,
    club_membership_discount_per_unit_fraction,
  } = data;

  const xQuantity = `x${quantity}`;

  return {
    id,
    quantity: quantity_of_split_parts_per_unit
      ? `${xQuantity}/${quantity_of_split_parts_per_unit}`
      : xQuantity,
    product_name,
    modifiers,
    retail_price: getCurrencyString(sku_price),
    discount_string: getCurrencyString(
      club_membership_discount_per_unit_fraction,
    ),
    discounted_price: getCurrencyString(sku_discounted_price),
    total_price: getCurrencyString(sub_total),
  };
};

export const getPaymentSummary = (data: Detalization): [string, string][] => {
  const {
    tips,
    total,
    promo_code,
    total_items_tax,
    total_items_fee,
    total_orders_shipping_cost,
    total_orders_shipping_tax,
    total_items_club_membership_discount,
    total_orders_shipping_cost_promo_code_discount,
    total_items_promo_code_discount,
    total_items_subtotal,
  } = data;

  const hasPromocode =
    !!total_items_promo_code_discount ||
    !!total_orders_shipping_cost_promo_code_discount;

  const promocodeName = promo_code?.name ? `(${promo_code.name})` : '';

  const summary: [string, string][] = [
    [
      'Member’s Discount',
      getCurrencyString(total_items_club_membership_discount),
    ],
    ['Sub-Total', getCurrencyString(total_items_subtotal)],
    ['Tax', getCurrencyString(total_items_tax)],
    ['Fee', getCurrencyString(total_items_fee)],
    ['Shipping Fee', getCurrencyString(total_orders_shipping_cost ?? 0)],
    ['Shipping Tax', getCurrencyString(total_orders_shipping_tax ?? 0)],
    ['Gratuity', getCurrencyString(tips)],
    ['Total', getCurrencyString(total)],
  ];

  if (hasPromocode) {
    summary.splice(1, 0, [
      `Promo${promocodeName}`,
      getCurrencyString(data.total_items_promo_code_discount ?? 0),
    ]);
  }

  return summary;
};
