import { SingleValue } from 'react-select';
import { IconTrash } from '@tabler/icons-react';
import { ActionIcon, Grid, NumberInput } from '@mantine/core';
import { SelectAsync } from 'components';
import { SelectOption, SkuStatus } from 'shared';
import { useSkus } from 'hooks';
import { GiftPackageItem } from '../../../../types';

export const PackageItem = ({
  index,
  defaultValue,
  deleteDisabled,
  giftPackageItemPath,
  getInputProps,
  setFieldValue,
  clearFieldError,
  removeListItem,
}: GiftPackageItem) => {
  const { skuOptions, hasMore, isLoading, setPage, handleSetSearchString } =
    useSkus({
      isSelectAsync: true,
      inputParams: { status: SkuStatus.Enabled },
    });

  const handleChange = (e: SingleValue<SelectOption>) => {
    if (!e?.value)
      return setFieldValue(`${giftPackageItemPath}.${index}`, null);

    setFieldValue(`${giftPackageItemPath}.${index}`, {
      quantity: 0,
      sku_id: e.value,
      sku_name: e.label,
    });
    clearFieldError(`${giftPackageItemPath}.${index}.sku_name`);
  };

  return (
    <>
      <Grid.Col span={9}>
        <SelectAsync
          required
          isSearchable
          setPage={setPage}
          hasMore={hasMore}
          isLoading={isLoading}
          options={skuOptions}
          searchByString={({ search_string }) =>
            handleSetSearchString(search_string)
          }
          {...getInputProps(`${giftPackageItemPath}.${index}.sku_name`)}
          defaultValue={defaultValue}
          onChange={handleChange}
        />
      </Grid.Col>
      <Grid.Col span={2}>
        <NumberInput
          hideControls
          {...getInputProps(`${giftPackageItemPath}.${index}.quantity`)}
        />
      </Grid.Col>
      <Grid.Col span={1}>
        <ActionIcon
          disabled={deleteDisabled}
          onClick={() => removeListItem(giftPackageItemPath, index)}
        >
          <IconTrash size={16} />
        </ActionIcon>
      </Grid.Col>
    </>
  );
};
