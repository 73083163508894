import { useNavigate } from 'react-router-dom';
import { Badge } from '@mantine/core';
import { DataTable } from 'mantine-datatable';

import {
  EmployeeStatus,
  NestedRoutes,
  PROPS_DATA_TABLE,
  SEARCH_INPUT,
  StatusColor,
  StatusLabel,
  getEditPath,
} from 'shared';
import {
  ContentLayout,
  CreateButton,
  SearchInput,
  SearchInputSingleLayout,
} from 'components';
import { useEmployees } from 'hooks';
import { FilteredLists } from '../filteredLists';

export const List = ({
  canCreate,
  rootPath,
}: {
  canCreate: boolean;
  rootPath: string;
}): JSX.Element => {
  const navigate = useNavigate();
  const {
    page,
    field,
    direction,
    search_string,
    employeesOutput,
    isLoading,
    totalRecords,
    updateFilters,
    setSortStatus,
    handleSetSearchParams,
  } = useEmployees({
    page_size: 10,
  });
  return (
    <ContentLayout
      title="Employees"
      rightAction={
        canCreate && (
          <CreateButton
            data-testid="new-employee-btn"
            to={`${rootPath}/${NestedRoutes.Create}`}
          >
            + New Employee
          </CreateButton>
        )
      }
      footer={<FilteredLists />}
    >
      <SearchInputSingleLayout>
        <SearchInput
          data-testid={SEARCH_INPUT}
          handleSubmit={(value: string) =>
            updateFilters({ search_string: value })
          }
          label="Search"
          value={search_string}
        />
      </SearchInputSingleLayout>

      <DataTable
        {...PROPS_DATA_TABLE}
        fetching={isLoading}
        records={employeesOutput}
        columns={[
          {
            accessor: 'index',
            title: '#',
            textAlignment: 'right',
            width: 40,
            render: (r) => employeesOutput.indexOf(r) + 1,
          },
          {
            accessor: 'name',
            title: 'Employee Name',
            sortable: true,
          },
          {
            accessor: 'status',
            title: 'Status',
            sortable: true,
            width: 200,
            render: ({ status }) =>
              status !== EmployeeStatus.ACTIVE && (
                <Badge
                  variant="filled"
                  data-testid="item-status"
                  color={StatusColor[status]}
                >
                  {StatusLabel[status]}
                </Badge>
              ),
          },
          {
            accessor: 'location_name',
            title: 'Primary Location',
            sortable: true,
          },
          {
            accessor: 'role_name',
            title: 'Role',
            sortable: true,
            ellipsis: true,
          },
        ]}
        page={page}
        onPageChange={(p) => handleSetSearchParams({ page: p.toString() })}
        totalRecords={totalRecords}
        sortStatus={{
          columnAccessor: field as string,
          direction: direction ?? 'asc',
        }}
        onSortStatusChange={setSortStatus}
        onRowClick={({ id }) => navigate(getEditPath(rootPath, id))}
      />
    </ContentLayout>
  );
};
