import { Grid, Select } from '@mantine/core';
import { SearchInput } from 'components';
import {
  getEmptyOption,
  orderPaymentStatusOptions,
  OrderStatus,
  OrderPaymentStatus,
  salesOrderStatusOptions,
} from 'shared';

type Props = {
  handleChange: (v: { [key: string]: string | string[] }) => void;
  values: {
    search_string: string;
    statuses: string[];
    payment_statuses: string[];
  };
};

export const Filters = ({ handleChange, values }: Props) => {
  const { statuses, payment_statuses, search_string } = values;

  const paymentStatuses = [
    OrderPaymentStatus.UNPAID,
    OrderPaymentStatus.PAID,
    OrderPaymentStatus.REFUND,
    OrderPaymentStatus.PARTIALLY_REFUND,
    OrderPaymentStatus.ERROR,
    OrderPaymentStatus.THREE_D_SECURE_CONFIRMATION,
  ];

  const orderStatuses = [
    OrderStatus.OPEN,
    OrderStatus.CANCEL,
    OrderStatus.SHIPPING,
    OrderStatus.WAITING_PICKUP,
    OrderStatus.PENDING_DELIVERY_METHOD_CHANGE,
  ];

  const paymentStatusOptions = orderPaymentStatusOptions.filter((i) =>
    paymentStatuses.includes(i.value),
  );

  const orderStatusOptions = salesOrderStatusOptions.filter((i) =>
    orderStatuses.includes(i.value),
  );

  return (
    <Grid mb="md" justify="space-between">
      <Grid.Col span={3}>
        <Select
          label="Status"
          value={statuses[0] ?? ''}
          searchable
          nothingFound="No options"
          data={[getEmptyOption('All'), ...orderStatusOptions]}
          onChange={(v) => handleChange({ statuses: v ?? '' })}
        />
      </Grid.Col>
      <Grid.Col span={3}>
        <Select
          label="Payment Status"
          value={payment_statuses[0] ?? ''}
          searchable
          nothingFound="No options"
          data={[getEmptyOption('All'), ...paymentStatusOptions]}
          onChange={(v) => handleChange({ payment_statuses: v ?? '' })}
        />
      </Grid.Col>

      <Grid.Col span={6}>
        <SearchInput
          handleSubmit={(v: string) => handleChange({ search_string: v })}
          label="Search"
          value={search_string}
        />
      </Grid.Col>
    </Grid>
  );
};
