import { Grid, Text } from '@mantine/core';
import { useForm } from '@mantine/form';
import { GeneralInfo, SummaryOrder } from 'components';
import { DataTable } from 'mantine-datatable';
import {
  GeneralInfo as GeneralInfoType,
  getCurrencyString,
  OrderDto,
  PROPS_DATA_TABLE,
} from 'shared';

type Props = {
  initialValues?: GeneralInfoType;
  order?: OrderDto;
  summary?: [string, string][];
  giftPackageName?: string;
  defaultClubMembership: {
    bottleQty: number;
    text: string;
  };
};

export const EditSuborderForm = ({
  initialValues,
  order,
  summary,
  giftPackageName,
  defaultClubMembership,
}: Props) => {
  const form = useForm({
    initialValues,
  });
  const {
    items,
    shipping_address,
    supply_type,
    deplete_inventory_location,
    deplete_location,
    freight_company,
    freight_method,
    shipping_date,
    tracking_number,
  } = order ?? {};

  const { address_line_1, state, country, city, zip_code } =
    shipping_address ?? {};

  const shippingAddress = `${address_line_1},${city},${state},${zip_code},${country}`;

  return (
    <form onSubmit={form.onSubmit(() => null)}>
      <GeneralInfo<GeneralInfoType>
        form={form}
        clubMemberShip={defaultClubMembership}
      />
      <Grid mb="xl" gutter="xl">
        <Grid.Col mb="xs">
          <Text size="lg" weight={600}>
            Inventory Details
          </Text>
        </Grid.Col>

        <Grid.Col>
          <Text size="sm" weight={600} mb={5}>
            Gift Package Choice
          </Text>
          <Text size="sm">{giftPackageName}</Text>
        </Grid.Col>
        <Grid.Col>
          <DataTable
            minHeight={80}
            emptyState={PROPS_DATA_TABLE.emptyState}
            records={items}
            borderColor="transparent"
            rowBorderColor="transparent"
            columns={[
              {
                accessor: 'sku_name',
                title: 'Product Name',
                width: '25%',
              },
              {
                accessor: 'quantity',
                title: 'Qty',
                width: '35%',
              },
              {
                accessor: 'price',
                title: 'Retail Price',
                textAlignment: 'right',
                width: '11%',
                render: ({ price }) => getCurrencyString(price),
              },
              {
                accessor: 'total',
                title: 'Total',
                width: '11%',
                textAlignment: 'right',
                render: ({ total }) => getCurrencyString(total),
              },
            ]}
          />
        </Grid.Col>

        <Grid.Col>
          {summary?.map((item) => <SummaryOrder key={item[0]} item={item} />)}
        </Grid.Col>

        <Grid.Col mb="xs">
          <Text size="lg" weight={600}>
            Delivery Information
          </Text>
        </Grid.Col>
        <Grid.Col>
          <Text size="sm" weight={600}>
            Shipping Address: {shippingAddress}
          </Text>
        </Grid.Col>
        <Grid.Col span={4}>
          <Text size="sm" weight={600}>
            Phone Number: {shipping_address?.phone_number}
          </Text>
        </Grid.Col>
        <Grid.Col span={4}>
          <Text size="sm" weight={600}>
            Email Address: {shipping_address?.email}
          </Text>
        </Grid.Col>
        <Grid.Col span={4}>
          <Text size="sm" weight={600}>
            Date of Birth: {shipping_address?.date_of_birth}
          </Text>
        </Grid.Col>
        <Grid.Col span={4}>
          <Text size="sm" weight={600}>
            Supply Type: {supply_type}
          </Text>
        </Grid.Col>
        <Grid.Col span={4}>
          <Text size="sm" weight={600}>
            Deplete from Primary Location: {deplete_location?.name}
          </Text>
        </Grid.Col>
        <Grid.Col span={4}>
          <Text size="sm" weight={600}>
            Deplete from Inventory Location: {deplete_inventory_location?.name}
          </Text>
        </Grid.Col>
        <Grid.Col span={4}>
          <Text size="sm" weight={600}>
            Freight Company: {freight_company}
          </Text>
        </Grid.Col>
        <Grid.Col span={4}>
          <Text size="sm" weight={600}>
            Freight Method: {freight_method}
          </Text>
        </Grid.Col>
        <Grid.Col span={4}>
          <Text size="sm" weight={600}>
            Shipping Date: {shipping_date}
          </Text>
        </Grid.Col>
        <Grid.Col span={4}>
          <Text size="sm" weight={600}>
            Tracking #: {tracking_number ?? '-'}
          </Text>
        </Grid.Col>
      </Grid>
    </form>
  );
};
