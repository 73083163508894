import { randomId } from '@mantine/hooks';
import {
  CorporateOrderDto,
  CorporateOrderListDto,
  CorporateOrderPayload,
  CorporateSubOrderList,
  DATE_FORMAT_SLASH,
  formatDate,
  GeneralInfo,
  getClubMembership,
  getDate,
  getDateSOutput,
  getFullName,
  orderComplianceStatus,
  salesOrderStatusLabels,
} from 'shared';

export const getNewProduct = () => ({
  key: randomId(),
  quantity: 0,
});

export const getNewPackage = (index = 1) => ({
  name: `Gift Package ${index}`,
  gift_package_items: [getNewProduct()],
});

export const normalizeCorporateOrdersList = (items: CorporateOrderListDto[]) =>
  items.map(
    ({ id, number, customer, created_at, total, sub_orders, status, card }) => {
      const { last4 } = card;
      const { first_name, last_name } = customer;
      const fullName = getFullName({ first_name, last_name });
      const createdAt = getDateSOutput(created_at);

      return {
        id,
        number,
        createdAt,
        total,
        last4,
        customer: fullName,
        subOrders: sub_orders,
        status: salesOrderStatusLabels[status],
      };
    },
  );

export const normalizeCorporateSubOrdersList = (
  items: CorporateSubOrderList[],
) =>
  items.map(({ id, number, customer, gift_package, compliance_status }) => {
    const { first_name, last_name, add_on_tier, main_tier } = customer;
    const choice = gift_package?.name || '-';

    const complianceCheck = orderComplianceStatus[compliance_status];
    const recipientName = getFullName({ first_name, last_name });
    const membership = getClubMembership({
      mainTier: main_tier,
      addOnTier: add_on_tier,
    }).text;

    return {
      id,
      number,
      recipientName,
      membership,
      choice,
      complianceCheck,
    };
  });

export const getGeneralInformation = (data: CorporateOrderDto): GeneralInfo => {
  const {
    number,
    status,
    channel,
    processed_in,
    payment_status,
    updated_by,
    created_at,
    customer,
  } = data ?? {};
  const { id: idWineAdvisor, first_name, last_name } = updated_by ?? {};

  const defaultWineAdvisor = {
    value: idWineAdvisor,
    label: getFullName({ first_name, last_name }),
  };

  const createdAt = formatDate(created_at, DATE_FORMAT_SLASH);

  return {
    status,
    number,
    createdAt,
    customer,
    channel,
    payment_status,
    defaultWineAdvisor,
    updated_by_id: updated_by.id,
    processedIn: processed_in,
  };
};

export const getInitialValues = (
  data?: CorporateOrderDto,
  defaultCardId?: string,
): CorporateOrderPayload | undefined => {
  if (!data) return;

  const {
    id,
    supply_type,
    delivery_method,
    gift_packages,
    shipping_date,
    freight_method,
    freight_company,
    recipients_file_url,
  } = data;

  const {
    status,
    number,
    createdAt,
    customer,
    channel,
    payment_status,
    updated_by_id,
    processedIn,
    defaultWineAdvisor,
  } = getGeneralInformation(data);

  const giftPackages = gift_packages.map(({ gift_package_items, ...rest }) => {
    return {
      ...rest,
      gift_package_items: gift_package_items.map(({ id, sku, quantity }) => ({
        quantity,
        id: id ?? null,
        sku_id: sku?.id,
        sku_name: `[${sku?.sku_number}] ${sku?.product_name}`,
      })),
    };
  });

  return {
    id,
    status,
    number,
    channel,
    customer,
    supply_type,
    payment_status,
    delivery_method,
    createdAt,
    shipping_date: getDate(shipping_date),
    freight_method,
    freight_company,
    updated_by_id,
    recipients_file_url,
    gift_packages: giftPackages,
    customer_id: customer?.id,
    customer_card_id: defaultCardId ?? '',
    processedIn,
    defaultWineAdvisor,
  };
};
