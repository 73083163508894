import { useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button } from '@mantine/core';
import { ContentLayout, BackButton } from 'components';
import { useEditOrders } from 'hooks';
import { SalesOrderForm } from '../form';
import { Mode } from '../../constants';

type PropType = {
  onExit: () => void;
};

export const Edit = ({ onExit }: PropType): JSX.Element => {
  const navigate = useNavigate();
  const [mode, setMode] = useState<Mode>(Mode.EDIT);
  const { id = '' } = useParams();
  const {
    order,
    clubMemberShip,
    payment,
    cards,
    initialValues,
    linkOrders,
    isLoading,
    isUpdating,
    isPaying,
    isCanceling,
    isResending,
    isRefunding,
    generationPDFPaymentId,
    resend,
    handleCancel,
    confirmAndPay,
    handleRefund,
    handleRegenerateReceipt,
    handleSendReceipt,
    handleSubmit,
    handleRecalculatePaidOrder,
  } = useEditOrders({
    id,
    queryKey: 'sales',
    onExit,
  });

  const title = `Order ${order?.number}`;

  const isProcessing = isPaying || isCanceling;

  return (
    <ContentLayout
      title={title}
      processing={isProcessing}
      isLoading={isLoading}
      leftAction={<BackButton component={Link} onClick={() => navigate(-1)} />}
      rightAction={
        <Button
          disabled={mode === Mode.HISTORY}
          onClick={() => setMode(Mode.HISTORY)}
        >
          See Compliance History
        </Button>
      }
    >
      {initialValues && order && (
        <SalesOrderForm
          mode={mode}
          clubMemberShip={clubMemberShip}
          goBack={() => setMode(Mode.EDIT)}
          goChangeHistory={() => setMode(Mode.CHANGE_HISTORY)}
          order={order}
          cards={cards}
          payments={payment}
          linkOrders={linkOrders}
          initialValues={initialValues}
          isUpdating={isUpdating}
          isResending={isResending}
          isRefunding={isRefunding}
          generationPDFPaymentId={generationPDFPaymentId}
          resend={resend}
          confirmAndPay={confirmAndPay}
          handleCancel={handleCancel}
          handleRefund={handleRefund}
          handleRegenerateReceipt={handleRegenerateReceipt}
          handleSendReceipt={handleSendReceipt}
          handleSubmit={handleSubmit}
          handleRecalculatePaidOrder={handleRecalculatePaidOrder}
        />
      )}
    </ContentLayout>
  );
};
