import { ReactNode, createContext, useContext, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useToggle } from '@mantine/hooks';

import { ShipmentSalesOrdersService } from 'App/api';
import { useApiMutation } from 'hooks/useApiMutation';
import { OrderDto, RecalculateAndPayRequest, OrderStatus } from 'shared';
import { BulkButton, BulkActionButtons } from './components';
import { useToast } from 'hooks/useToast';

type SelectedType = {
  selectedRecords: OrderDto[];
  onSelectedRecordsChange: (selectedRecords: OrderDto[]) => void;
};

export type TBulkActionContextValues = {
  isLoading: boolean;
  bulkActionButtons: ReactNode;
  bulkButton: ReactNode;
  selected: SelectedType;
};

type BulkActionProps = {
  children: ReactNode;
  rootPath: string;
};

const BulkActionContext = createContext<TBulkActionContextValues>({
  isLoading: false,
  bulkActionButtons: null,
  bulkButton: null,
  selected: { selectedRecords: [], onSelectedRecordsChange: () => null },
});

const { Provider } = BulkActionContext;

const BulkActionProvider = ({
  children,
  rootPath,
}: BulkActionProps): JSX.Element => {
  const [selectedRecords, setSelectedRecords] = useState<OrderDto[]>([]);
  const [showBulkAction, toggle] = useToggle();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const addToast = useToast();

  const { mutate, isLoading } = useApiMutation(
    ['recalculate-and-paid-order'],
    ShipmentSalesOrdersService.recalculateAndPaidOrder,
    {
      onSuccess: () => {
        toggle();
        setSelectedRecords([]);
        addToast.success(
          'The delivery information has been successfully changed!',
        );
        navigate(rootPath);
      },
    },
  );

  const statuses = searchParams.get('statuses') as OrderStatus;
  const orderIds = selectedRecords.map(({ id }) => id);
  const handleSubmit = (data: RecalculateAndPayRequest) => mutate(data);

  const isWaitingPickup = statuses === OrderStatus.WAITING_PICKUP;

  const value: TBulkActionContextValues = {
    isLoading,
    bulkActionButtons:
      showBulkAction && isWaitingPickup ? (
        <BulkActionButtons
          loading={isLoading}
          orderIds={orderIds}
          toggle={toggle}
          handleSubmit={handleSubmit}
        />
      ) : null,
    bulkButton: !showBulkAction ? (
      <BulkButton statuses={statuses} toggle={toggle} />
    ) : null,
    selected: {
      ...(showBulkAction &&
        isWaitingPickup && {
          selectedRecords: selectedRecords,
          onSelectedRecordsChange: setSelectedRecords,
        }),
    } as SelectedType,
  };

  return <Provider value={value}>{children}</Provider>;
};

const useBulkAction = (): TBulkActionContextValues => {
  return useContext(BulkActionContext);
};

export { BulkActionProvider, BulkActionContext, useBulkAction };
