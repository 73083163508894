import { Link } from 'react-router-dom';
import { ContentLayout, BackButton } from 'components';
import { CreateOrderForm } from '../form';
import { useCreateOrder } from '../../hooks';

type Props = {
  rootPath: string;
};

const title = 'New Order';

export const Create = ({ rootPath }: Props): JSX.Element => {
  const {
    initialValues,
    asyncSelectUsers,
    isLoadingUsers,
    isCreatingOrder,
    clubMemberShip,
    cardOptions,
    getCustomerDataById,
    handleCreateOrder,
  } = useCreateOrder({ rootPath });

  return (
    <ContentLayout
      title={title}
      processing={isCreatingOrder}
      leftAction={<BackButton component={Link} to={rootPath} />}
    >
      <CreateOrderForm
        initialValues={initialValues}
        isLoadingUsers={isLoadingUsers}
        asyncSelectUsers={asyncSelectUsers}
        clubMemberShip={clubMemberShip}
        cardOptions={cardOptions}
        handleCreateOrder={handleCreateOrder}
        getCustomerDataById={getCustomerDataById}
      />
    </ContentLayout>
  );
};
