import { CorporateOrder } from 'App/api';
import { useApiMutation } from 'hooks';
import { useState } from 'react';

export const useApplyPromocode = ({
  orderId,
  refetchOrder,
}: {
  orderId?: string;
  refetchOrder: () => void;
}) => {
  const [promocodeId, setPromocodeId] = useState<string | null>(null);

  const { mutate, isLoading: isApplied } = useApiMutation(
    ['applyPromocode'],
    CorporateOrder.addRemovePromocode,
    { onSuccess: () => refetchOrder() },
  );

  const handleApplyPromocode = () => {
    mutate({ promocodeId, orderId });
  };

  return { promocodeId, isApplied, setPromocodeId, handleApplyPromocode };
};
