import { randomId } from '@mantine/hooks';
import { omit, reduce } from 'rambda';

import {
  CycleOrderPayload,
  OrderDto,
  WalletSelectOption,
  formatDate,
  WineTypeTitle,
  InventoryDetail,
  getFullName,
  Summary,
  getCurrencyString,
  PaymentMethod,
  capitalizeText,
  Payments,
  ServiceClass,
  getEditPath,
  CardDto,
  OrderType,
  labelOrder,
  SalesOrderComplianceStatus,
  getDateSOutput,
  getDate,
  RefundedItemCost,
  CorrectionOrder,
  TireAddonData,
  ShipmentCycleShortItem,
  DATE_FORMAT_SLASH,
} from 'shared';

export const normalizeOrder = (order: OrderDto): OrderDto => {
  return {
    ...order,
    created_at: getDateSOutput(order.created_at),
    fully_paid_at: getDateSOutput(order.fully_paid_at),
  };
};

export const getOrderValues = (order: OrderDto): CycleOrderPayload => {
  const {
    status,
    items,
    delivery_method,
    supply_type,
    deplete_inventory_location,
    deplete_location,
    pick_up_deadline,
    freight_company,
    freight_method,
    shipping_date,
    tracking_number,
    billing_datetime,
    re_billing_datetime,
    shipping_address,
    updated_by,
    checkout,
    compliance_status,
    channel,
    payment_status,
    shipment_cycle: shipmentCycle,
    number,
    created_at,
    customer,
    processed_in: processedIn,
  } = order;
  const { id, first_name, last_name } = updated_by ?? {};
  const defaultWineAdvisor = {
    value: id,
    label: getFullName({ first_name, last_name }),
  };

  const { promocode_data } =
    items.find(({ promocode_data }) => promocode_data) ?? {};

  const defaultDiscount = promocode_data && {
    value: promocode_data.id,
    label: promocode_data.name,
  };

  const createdAt = formatDate(created_at, DATE_FORMAT_SLASH);

  return {
    number,
    createdAt,
    status,
    items,
    channel,
    customer,
    processedIn,
    delivery_method,
    supply_type,
    payment_status,
    pick_up_deadline: getDate(pick_up_deadline),
    customer_shipping_address_id: shipping_address && 'null',
    deplete_location_id: deplete_location?.id ?? null,
    deplete_inventory_location_id: deplete_inventory_location?.id ?? null,
    freight_company,
    freight_method,
    shipping_date: getDate(shipping_date),
    tracking_number: tracking_number ?? '',
    billing_datetime: billing_datetime && new Date(billing_datetime),
    re_billing_datetime: re_billing_datetime && new Date(re_billing_datetime),
    customer_card_id: 'null',
    updated_by_id: id,
    checkout,
    compliance_status,
    defaultWineAdvisor,
    shipmentCycle,
    defaultDiscount,
    promocode_id: promocode_data?.id ?? null,
  };
};

export const getOrderDeliveryPayload = (
  data: CycleOrderPayload,
): Partial<CycleOrderPayload> => {
  const {
    delivery_method,
    customer_shipping_address_id,
    pick_up_deadline,
    freight_company,
    freight_method,
    shipping_date,
    tracking_number,
  } = data;

  const isPickup = delivery_method === 'Pickup';

  const deliveryData: Partial<CycleOrderPayload> = {
    freight_company: null,
    freight_method: null,
    shipping_date: null,
    tracking_number: null,
    customer_shipping_address_id: null,
    pick_up_deadline: null,
  };

  if (isPickup) {
    return {
      ...deliveryData,
      pick_up_deadline: formatDate(pick_up_deadline),
    };
  }

  return {
    ...deliveryData,
    shipping_date: formatDate(shipping_date),
    freight_company,
    freight_method,
    tracking_number: !tracking_number ? null : tracking_number,
    customer_shipping_address_id:
      customer_shipping_address_id === 'null'
        ? null
        : (customer_shipping_address_id as string),
  };
};

export const getPayRecalculateData = (values: CycleOrderPayload) => {
  const {
    delivery_method,
    supply_type,
    deplete_location_id,
    deplete_inventory_location_id,
    customer_shipping_address_id,
    freight_company,
    freight_method,
    shipping_date,
    customer_card_id,
    pick_up_deadline,
  } = values;
  return {
    delivery_method,
    supply_type,
    deplete_location_id,
    deplete_inventory_location_id,
    customer_shipping_address_id:
      customer_shipping_address_id === 'null'
        ? null
        : customer_shipping_address_id,
    freight_company,
    freight_method,
    shipping_date: formatDate(shipping_date),
    pick_up_deadline: formatDate(pick_up_deadline),
    customer_card_id: customer_card_id === 'null' ? null : customer_card_id,
    source: 'alice',
  };
};

export const getOrderPayload = (data: CycleOrderPayload): CycleOrderPayload => {
  const withoutSkipped = omit(['is_skipped'], data);
  const { customer_card_id, ...order } = withoutSkipped;
  return {
    ...order,
    customer_card_id: customer_card_id === 'null' ? null : customer_card_id,
    ...getOrderDeliveryPayload(data),
  };
};

export const walletsOptions = (wallets?: CardDto[]): WalletSelectOption[] => {
  if (!wallets) return [];

  return wallets?.map(
    ({ id = '', card_brand, card_last_4, stripe_card_id }) => {
      const numberCard = `${card_brand} **** **** **** ${card_last_4}`;

      return {
        value: id,
        label: numberCard,
        stripe_card_id,
      };
    },
  );
};

export const getClubMembership = ({
  mainTier,
  addOnTier,
  shipmentCycle,
}: {
  mainTier?: TireAddonData | null;
  addOnTier?: TireAddonData | null;
  shipmentCycle?: ShipmentCycleShortItem;
}) => {
  const isMainType = shipmentCycle?.club_tier_id === mainTier?.club_tier?.id;

  if (!mainTier) {
    return {
      bottleQty: 6,
      text: 'Not a member',
    };
  }

  const mainTierName = mainTier.club_tier?.name ?? '';
  const mainBottleQuantity = mainTier.bottle_quantity ?? '';
  const mainWineType = WineTypeTitle[mainTier.wine_type] ?? '';

  if (!addOnTier) {
    const text = mainBottleQuantity
      ? `${mainTierName}, ${mainBottleQuantity} ${mainWineType}`
      : mainTierName;

    return {
      bottleQty: mainBottleQuantity,
      text,
    };
  }

  const addOnTierName = addOnTier.club_tier?.name ?? '';
  const addOnBottleQuantity = addOnTier.bottle_quantity ?? '';
  const addOnWineType = WineTypeTitle[addOnTier.wine_type] ?? '';

  const mainTierText = `${mainTierName} + ${addOnTierName}`;
  const bottleQuantity = isMainType ? mainBottleQuantity : addOnBottleQuantity;
  const wineType = isMainType ? mainWineType : addOnWineType;

  return {
    bottleQty: bottleQuantity,
    text: bottleQuantity
      ? `${mainTierText}, ${bottleQuantity} ${wineType}`
      : mainTierText,
  };
};

export const getNewInventoryDetailItem = (): InventoryDetail => ({
  key: randomId(),
  sku_id: '',
  quantity: 0,
  sku_name: '',
  price: 0,
  discount: 0,
  total: 0,
});

export const getCheckoutSummary = (data: Summary): [string, string][] => {
  const {
    discounts,
    items_price,
    taxes,
    fees,
    tips,
    total,
    shipping_cost,
    shipping_taxes,
    promocode_name,
    promocode_discounts,
  } = data;

  const subTotal = items_price - discounts - (promocode_discounts ?? 0);
  const hasPromocode = !!promocode_discounts;

  const promocodeName = promocode_name ? `(${promocode_name})` : '';

  const summary: [string, string][] = [
    ['Member’s Discount', getCurrencyString(discounts)],
    ['Sub-Total', getCurrencyString(subTotal)],
    ['Tax', getCurrencyString(taxes)],
    ['Fee', getCurrencyString(fees)],
    ['Shipping Fee', getCurrencyString(shipping_cost ?? 0)],
    ['Shipping Tax', getCurrencyString(shipping_taxes ?? 0)],
    ['Gratuity', getCurrencyString(tips)],
    ['Total', getCurrencyString(total)],
  ];

  if (hasPromocode) {
    summary.splice(1, 0, [
      `Promo${promocodeName}`,
      getCurrencyString(data.promocode_discounts ?? 0),
    ]);
  }

  return summary;
};

export const getPaymentMethod = ({
  method,
  card_brand = '',
  card_last4 = '',
}: {
  method: PaymentMethod;
  card_brand: string | null;
  card_last4: string | null;
}) => {
  if (method === PaymentMethod.cash) return capitalizeText(method);

  if (method === PaymentMethod.card && card_brand)
    return `${capitalizeText(card_brand)} **** **** **** ${card_last4}`;
};

export const normalizeOrderItemTable = (data: InventoryDetail) => {
  const { quantity, discount, id, sku_name, price, refunded_quantity } = data;
  const discounted_price = price - discount;
  const totalPrice = discounted_price * quantity;

  return {
    id,
    quantity,
    refunded_quantity,
    product_name: sku_name,
    retail_price: getCurrencyString(price),
    discount_string: getCurrencyString(discount),
    discounted_price: getCurrencyString(discounted_price),
    total_price: getCurrencyString(totalPrice),
  };
};

const getOrderType = ({
  type,
  service_class,
}: {
  type: OrderType;
  service_class: ServiceClass;
}) => {
  if (!service_class && OrderType.pos === type) {
    return OrderType.pos;
  }
  if (ServiceClass.wine_club === service_class) {
    return OrderType.cycle;
  }
  if (ServiceClass.regular === service_class) {
    return OrderType.shipment;
  }
};

export const getOrderRouteByType = ({
  id,
  type,
  service_class,
}: {
  id: string;
  type: OrderType;
  service_class: ServiceClass;
}) => {
  const orderType = getOrderType({ type, service_class });

  if (orderType === OrderType.pos) {
    return getEditPath('/orders/pos-orders', id);
  }
  if (orderType === OrderType.cycle) {
    return getEditPath('/members-club/cycle-orders', id);
  }
  if (orderType === OrderType.shipment) {
    return getEditPath('/orders/sales-orders', id);
  }

  return '';
};

export const normalizeLinkOrders = (
  orders: Payments['sales_orders'],
  id: string,
) => {
  const grandTotal =
    orders && reduce((prev, current) => prev + current.total, 0, orders);

  const withoutCurrentOrder = orders?.filter((order) => order.id !== id);

  const ordersList = {
    items: withoutCurrentOrder?.map((order) => {
      const { id, number, service_class, type, total } = order;
      const orderType = getOrderType({ type, service_class });
      const orderName = orderType && `${labelOrder[orderType]} ORDER`;

      return {
        label: `ORDER ${number} (${orderName})`,
        link: getOrderRouteByType({ id, type, service_class }),
        total: getCurrencyString(total),
      };
    }),
    grandTotal: grandTotal ? getCurrencyString(grandTotal) : '',
  };
  return ordersList;
};

export const getComplianceStatus = (
  compliance_status: SalesOrderComplianceStatus,
) => {
  const isError = [
    SalesOrderComplianceStatus.ERROR,
    SalesOrderComplianceStatus.NOT_COMPLIANT,
  ].includes(compliance_status);

  const isCompliant =
    SalesOrderComplianceStatus.COMPLIANT === compliance_status;

  if (isError) return '#FF6B6B';

  if (isCompliant) return '#3EA8D5';
};

export const getSummaryRefundedItem = (data?: RefundedItemCost) => {
  if (!data) return [];
  const {
    items_full_price,
    sub_total,
    total_taxes,
    shipping_cost,
    total_fees,
    shipping_tax,
    tips,
    total_promocode_discount,
  } = data;

  const summary: [string, string][] = [
    ['Sub-Total', getCurrencyString(sub_total)],
    ['Promocode Discount', getCurrencyString(total_promocode_discount)],
    ['Tax', getCurrencyString(total_taxes)],
    ['Fee', getCurrencyString(total_fees)],
    ['Shipping Tax', getCurrencyString(shipping_tax)],
    ['Shipping Fee', getCurrencyString(shipping_cost ?? 0)],
    ['Gratuity', getCurrencyString(tips)],
    ['Total', getCurrencyString(items_full_price)],
  ];

  return summary;
};

export const getRecalculationSummary = (
  data: CorrectionOrder[],
): { id: string; summary: [string, string][] }[] => {
  return data.map(
    ({
      id,
      tax_correction,
      shipping_correction,
      fee_correction,
      total_due_correction,
    }) => ({
      id,
      summary: [
        ['Tax Correction', getCurrencyString(tax_correction)],
        ['Shipping', getCurrencyString(shipping_correction)],
        ['Fee', getCurrencyString(fee_correction)],
        ['Total Due', getCurrencyString(total_due_correction)],
      ],
    }),
  );
};

export const normalizeCorrections = (data: OrderDto) => ({
  ...data,
  corrections: [
    ...data.corrections,
    ...(data.calculated_correction ? [data.calculated_correction] : []),
  ],
});

export const getPayments = ({
  card,
  cards,
}: {
  card?: {
    last4: string;
    brand: string;
    stripe_card_id: string;
  };
  cards: WalletSelectOption[];
}) => {
  if (card?.brand && card?.last4) {
    const cardWithoutId = {
      label: `${card?.brand} **** **** **** ${card?.last4} (Saved)`,
      value: 'null',
      stripe_card_id: card?.stripe_card_id,
    };

    const withoutDefaultCard = cards.filter(
      (card) => card.stripe_card_id !== cardWithoutId.stripe_card_id,
    );
    return [cardWithoutId, ...withoutDefaultCard];
  } else {
    return cards;
  }
};
