import { Box, Center, Container, RingProgress, Text } from '@mantine/core';
import { useCorporateOrderProgress } from '../../../hooks';
import { CorporateOrderDto, OrderStatus } from 'shared';

type Props = {
  status?: OrderStatus;
  errors?: CorporateOrderDto['errors'];
  handleRefetchOrder: () => void;
};

export const ProgressBar = ({ status, errors, handleRefetchOrder }: Props) => {
  const { progressData } = useCorporateOrderProgress({
    status,
    handleRefetchOrder,
  });

  const {
    progress: { task_completion_percentage },
  } = progressData ?? { progress: { task_completion_percentage: 0 } };

  return (
    <>
      <Container size="xs">
        <Center>
          <RingProgress
            sections={[
              { value: task_completion_percentage, color: 'halterBlue.7' },
            ]}
            label={
              <Text color="halterBlue.7" weight={700} ta="center" size="xl">
                {task_completion_percentage}%
              </Text>
            }
          />
        </Center>
      </Container>

      {!!errors?.length && (
        <Container size="lg" mt={20}>
          <Text>
            Error:{' '}
            {errors.map(({ id, message }) => (
              <Box key={id} component="span" style={{ whiteSpace: 'pre-line' }}>
                {message}
              </Box>
            ))}
          </Text>
        </Container>
      )}
    </>
  );
};
